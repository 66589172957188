import React,{ useContext, useEffect, useState, useRef, ChangeEvent } from 'react';
import {
  Button, Grid, TextField, MenuItem, TableContainer, Table,
  TableHead, TableBody, TableRow, TableCell, Typography, Snackbar,
  SvgIcon, InputAdornment, Autocomplete, Divider, Alert, Box, useMediaQuery, FormControlLabel, Switch, IconButton,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { MagicWand01, SearchSm } from '../../../assets';
import { useNavigate, useBlocker } from 'react-router-dom';
import { UserContext } from '../../../components/shared/useUser';
import { theme } from '../../../theme';
import { applyRoundAndFormat, base64ToBlob, standardizeDateFormat } from '../../../utils';
import { isAutoShipOrder, OrderType as OrderTypeEnum, OrderTypeTranslationMap } from '../../../enums/OrderType';
import { client, Category, OrderType, OrderForm, OrderFormUpdate, Supplier, OrderFormItem, OrderFormItemUpdate, OrderFormItemUpdateDate, OrderFormCategory } from '../../../app/services/api/orderManagementClient';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import LeavePageModal from '../../../components/shared/dialogs/LeavePageModal';
import ReloadPageModal from '../../../components/shared/dialogs/ReloadPageModal';
import { Action } from '../../../enums/Action';
import PageTitle from '../../../components/shared/PageTitle';
import Tooltip from '@mui/material/Tooltip';
import PageArea from '../../../components/shared/PageArea';
import BackButton from '../../../components/shared/actions/BackButton';
import { PredictedDemandModel, podClient } from './../../../app/services/api/podClient';
import { useTranslation } from 'react-i18next';
import './OrderForm.css';
import useNswagClient from './../../../hooks/api/useNswagClient';
import TextWithOverflowTooltip from '../../../components/shared/TextWithOverflowTooltip';
import useCurrencyCodeConfiguration from '../../../hooks/configurations/useCurrencyCodeConfiguration';
import { starbucksFranchises } from '../../../enums/FranchiseNames';
import { isOrderCategoryParent, OrdersCategoriesParentsMap, isOrderCategory, OrderCategoriesTranslationMap } from '../../../enums/OrderCategories';
import useLogError from '../../../hooks/useLogError';
import { QuantityWarningDialog } from './../../../components/shared/orders/QuantityWarningDialog';

const OrderFormPage = () => {

  const navigate = useNavigate();

  const { selectedStore } = useContext(UserContext);
  const { t } = useTranslation('orderForm');
  const { currencyCode, getCurrencyCodeConfiguration } = useCurrencyCodeConfiguration();

  const [lastFocusedItemId, setLastFocusedItemId] = useState<number | null>(null);
  const [inactivityTimer, setInactivityTimer] = useState<NodeJS.Timeout | null>(null);

  const INACTIVITY_TIMEOUT =  10000; // 10 seconds 

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [loading, setLoading] = useState(true);
  const [navigateToIndex, setNavigateToIndex] = useState<boolean>(false);

  const [productSearch, setProductSearch] = useState<string>('');
  const [orderType, setOrderType] = useState<number>(-1);
  const [supplier, setSupplier] = useState<string>('');
  const [category, setCategory] = useState<number>(-1);

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('Name');

  const [orderTypes, setOrderTypes] = useState<OrderType[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [showPredictedQuantities, setShowPredictedQuantities] = useState<boolean>(true);
  const [predictedDemands, setPredictedDemands] = useState<PredictedDemandModel[]>([]);
  const [orderForm, setOrderForm] = useState<OrderForm>();
  const { getConversions } = useNswagClient();
  const [isPodEnabled, setIsPodEnabled] = useState<boolean>(false);
  const [isExitAndSaveClicked, setIsExitAndSaveClicked] = useState<boolean>(false);

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallerThan1174px = useMediaQuery('(max-width: 1174px)');

  const [showQuantityWarningDialog, setShowQuantityWarningDialog] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>('');

  const { logError } = useLogError();
  const [inputValues, setInputValues] = React.useState<Record<string, number>>({});

  const showImage = !isSmallerThan1174px || isXsScreen;

  const [unsavedChangesModal, setUnsavedChangesModal] = useState<{
    open: boolean;
    action: Action;
    actionValue: string;
  }>({
    open: false,
    action: Action.None,
    actionValue: '',
  });

  const [orderFormUpdates, setOrderFormUpdates] = useState<OrderFormUpdate>({
    storeNumber: '',
    orderTypeId: -1,
    supplierNumber: '',
    items: [],
    dates: [],
  });

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'error' | 'warning' | 'info' | 'success';
  }>({
    open: false,
    message: '',
    severity: 'info',
  });

  const blocker = useBlocker(() => orderFormUpdates.items.length > 0 && !isExitAndSaveClicked);

  useEffect(() => {
    getCurrencyCodeConfiguration();
  }, []);

  useEffect(() => {
    loadData();
    loadPodStoreConfiguration();
  }, [selectedStore]);

  useEffect(() => {
    const beforeUnload = (event: { preventDefault: () => void; returnValue: string; }) => {
      if (orderFormUpdates.items.length > 0) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', beforeUnload);
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [orderFormUpdates.items]);

  useEffect(() => {
    if (navigateToIndex) {
      navigate('/store/orders-history');
      if (blocker?.state === 'blocked') {
        blocker.proceed();
      }
      setNavigateToIndex(false);
    }
  }, [navigateToIndex, blocker]);

  useEffect(() => {
    if (isPodEnabled && orderForm?.availableDates && orderForm.availableDates.length > 0) {
      loadPredictedDemands();
    }
  }, [isPodEnabled, orderForm?.availableDates]);

  useEffect(() => {
    if (orderFormUpdates.items.length > 0) {
      startInactivityTimer();
    }
  }, [orderFormUpdates.items]);

  const startInactivityTimer = () => {
    if (inactivityTimer) {
      clearTimeout(inactivityTimer);
    }
    const timer = setTimeout(() => {
      if (orderFormUpdates.items.length > 0) {
        autosaveItem();
      }
    }, INACTIVITY_TIMEOUT);
    setInactivityTimer(timer);
  };

  const handleItemFocus = (itemId: number) => {
    if (lastFocusedItemId !== null && lastFocusedItemId !== itemId && orderFormUpdates.items.length > 0) {
      autosaveItem();
    }
    setLastFocusedItemId(itemId);
  };

  const autosaveItem = async () => {

    if (orderFormUpdates.items.length > 0) {

      orderFormUpdates.orderTypeId = orderType;

      try {

        setSnackbar({
          open: true,
          message: t('autosaving'),
          severity: 'info',
        });

        const response = await client.saveOrderForm(
          selectedStore?.storeNumber,
          orderFormUpdates,
        );

        if (response.success) {

          updateOrderFormQuantities(orderFormUpdates.items);

          setOrderFormUpdates((prev) => ({
            ...prev,
            items: prev.items.filter(
              (item) => !orderFormUpdates.items.some((savedItem) => savedItem.id === item.id),
            ),
          }));

          setSnackbar({
            open: true,
            message: t('changesSaved'),
            severity: 'success',
          });
        } else {
          setSnackbar({
            open: true,
            message: response.message || t('autosaveFailed'),
            severity: 'error',
          });
          setShowQuantityWarningDialog(true);
          setWarningMessage(response.message);
          fixQuantities();
        }
      } catch (error) {
        logError(error);
        setSnackbar({
          open: true,
          message: t('autosaveFailed'),
          severity: 'error',
        });
      }
    }
  };

  const updateOrderFormQuantities = (savedItems: OrderFormItemUpdate[]) => {
    setOrderForm((prevOrderForm) => {
      if (!prevOrderForm) return prevOrderForm;
      const updatedCategories = prevOrderForm.categories.map((category) => {
        const updatedItems = category.items.map((item) => {
          const savedItem = savedItems.find((si) => si.id === item.id);
          if (savedItem) {
            const updatedQuantities = item.quantity.map((qty, index) => {
              const date = prevOrderForm.availableDates[index].deliveryDate;
              const savedDate = savedItem.dates.find((d) => d.date === date);
              return savedDate ? savedDate.quantity : qty;
            });
            return { ...item, quantity: updatedQuantities };
          } else {
            return item;
          }
        });
        return { ...category, items: updatedItems };
      });
      return { ...prevOrderForm, categories: updatedCategories };
    });
  };

  const loadData = async () => {
    setLoading(true);
    await fetchOrderTypes();
    setLoading(false);
  };

  const showMessageWhenNoCost = starbucksFranchises.includes(selectedStore?.franchiseName ?? '');

  const fetchOrderTypes = async () => {
    try {
      const orderTypesResponse = await client.getOrderTypes(selectedStore?.storeNumber);
      setOrderTypes(orderTypesResponse.data);
      if (orderTypesResponse.success && orderTypesResponse.data.length > 0) {
        const defaultOrderType = orderTypesResponse.success && orderTypesResponse.data.length > 0 ? orderTypesResponse.data[0].id : -1;
        setOrderType(defaultOrderType);
        await fetchSuppliers();
        await fetchCategories(defaultOrderType, supplier);
        await fetchOrderForm(defaultOrderType, category, orderBy, order, productSearch, supplier, true);
      }
    } catch (error) {
      handleSnackbarOpen(t('fetchOrderError') + ' ' + error, 'error');
    }
  };

  const fetchCategories = async (selectedOrderType: number, supplier: string) => {
    try {
      const categories = await client.getCategories(selectedStore?.storeNumber, selectedOrderType, supplier);
      setCategories(categories.data);
    } catch (error) {
      setCategories([]);
      handleSnackbarOpen(t('fetchOrderError') + ' ' + error, 'error');
    }
  };

  const fetchSuppliers = async () => {
    try {
      const suppliers = await client.getSuppliers(selectedStore?.storeNumber);
      setSuppliers(suppliers.data);
    } catch (error) {
      setSuppliers([]);
      handleSnackbarOpen(t('fetchOrderError') + ' ' + error, 'error');
    }
  };

  const fetchOrderForm = async (selectedOrderType: number,
    categoryId: number,
    orderBy: string,
    order: string,
    item: string,
    supplier: string,
    resetUpdates: boolean) => {
    try {
      const response = await client.getOrderForm(selectedStore?.storeNumber, selectedOrderType, categoryId, orderBy, order, item, supplier);
      response.data.availableDates = response.data.availableDates.map(item => {
        return {
          ...item,
          deliveryDate: standardizeDateFormat(item.deliveryDate),
        };
      });
      setOrderForm(response.data);
      if (resetUpdates) {
        setOrderFormUpdates({
          storeNumber: selectedStore?.storeNumber ?? '',
          orderTypeId: orderType || -1,
          supplierNumber: selectedOrderType === OrderTypeEnum.DirectStoreDelivery ? supplier : '',
          items: [],
          dates: response.data.availableDates.map(item => item.deliveryDate),
        });
      }
    } catch (error) {
      setOrderForm(undefined);
      handleSnackbarOpen(t('fetchOrderError') + ' ' + error, 'error');
    }
  };

  const loadPodStoreConfiguration = async () => {
    try {
      if (selectedStore?.franchiseName && selectedStore.storeNumber) {
        const response = await podClient.getStoreConfiguration(selectedStore.franchiseName, selectedStore.storeNumber);
        setIsPodEnabled(response.isPodEnabled);
      }
    } catch (error) {
      handleSnackbarOpen(t('fetchOrderError'), 'error');
    }
  };

  const loadPredictedDemands = async () => {
    setLoading(true);
    try {
      if (selectedStore?.franchiseName && selectedStore.storeNumber) {
        const predictions = await podClient.getPredictions(selectedStore.franchiseName, selectedStore.storeNumber, orderForm?.availableDates.map(x => x.deliveryDate) ?? []);
        await loadUomConversions(predictions);
      }
    } catch (error) {
      handleSnackbarOpen(t('fetchOrderError'), 'error');
    } finally {
      setLoading(false);
    }
  };

  const loadUomConversions = async (predictions: PredictedDemandModel[]) => {
    const itemNumbers = predictions.map(x => x.itemNumber);
    const items = orderForm?.categories.flatMap(x => x.items).filter(x => itemNumbers.includes(x.sku));

    const response = await getConversions(undefined, undefined, undefined, undefined, selectedStore?.storeNumber);
    const conversions = response.conversions?.filter(conversion => {
      const item = items?.find(i => i.sku === conversion?.itemId);
      return item && item.primaryUom === conversion?.fromUom && item.orderableUom === conversion.toUom;
    });

    predictions.forEach((x) => {
      const conversion = conversions?.find(c => c?.itemId === x.itemNumber);
      const conversionNumber = conversion?.conversionNumber ?? 1;

      x.predictedDemandItems = x.predictedDemandItems.map(({ quantity, ...rest }) => ({
        ...rest,
        quantity: Math.ceil(quantity / conversionNumber),
      }));
    });

    setPredictedDemands(predictions);
  };

  const handleSearch = async () => {
    setLoading(true);
    await fetchCategories(orderType, orderType === OrderTypeEnum.DirectStoreDelivery ? supplier : '');
    await fetchOrderForm(orderType, category, orderBy, order, productSearch, supplier, true);
    setLoading(false);
  };

  const handleSave = async () => {
    orderFormUpdates.orderTypeId = orderType;
    updatePredictedQuantities(orderFormUpdates.items);
    try {
      setLoading(true);

      const response = await client.saveOrderForm(selectedStore?.storeNumber, orderFormUpdates);
      setLoading(false);
      if (response.success) {
        handleSnackbarOpen(t('saveSuccess'), 'success');
        await fetchOrderForm(orderType, category, orderBy, order, productSearch, supplier, true);

      }
      else {
        setShowQuantityWarningDialog(true);
        setWarningMessage(response.message);
        fixQuantities();
      }
    } catch (error) {
      setLoading(false);
    }
    finally {
      setLoading(false);
    }
  };

  const handleExitAndSave = async () => {
    setIsExitAndSaveClicked(true);
    if (!loading && orderFormUpdates.items.length > 0) {
      await handleSave();
      setNavigateToIndex(true);
    }
  };

  const updatePredictedQuantities = (items: OrderFormItemUpdate[]) => {
    predictedDemands.forEach(x => {
      const index = items.findIndex(i => i.sku === x.itemNumber);
      const originalItem = orderForm?.categories.flatMap(category => category.items).find(item => item.sku === x.itemNumber);
      const currentItem = items.find(i => i.id === originalItem?.id);

      const newDates = orderForm?.availableDates.map((date, idx) => {

        const existingItem = currentItem?.dates.find(i => {
          const itemDate = i.date.split('T')[0];
          return itemDate === date.deliveryDate;
        });

        if (!existingItem) return undefined;

        const predictedDemandItem = x.predictedDemandItems[idx];
        const predictedQuantity = predictedDemandItem.hasPrediction ? predictedDemandItem.quantity : undefined;

        const newDate: OrderFormItemUpdateDate = {
          date: date.deliveryDate,
          quantity: existingItem.quantity,
          predictedQuantity: predictedQuantity,
        };
        return newDate;
      }).filter(x => x !== undefined) as OrderFormItemUpdateDate[];

      if (index < 0) {
        return;
      }
      else {
        items[index].dates = newDates ?? [];
      }
    });
  };

  const handleClearFilters = async () => {
    setProductSearch('');
    setCategory(-1);
    setOrderBy('Name');
    setOrder('asc');
    setLoading(true);
    await fetchOrderForm(orderType, -1, 'Name', 'asc', '', supplier, true);
    setLoading(false);
  };

  const handleOrderTypeChange = async (changedOrderType: string) => {
    const newOrderType = Number(changedOrderType);
    if (unsavedChangesModal?.open === false && orderFormUpdates.items.length > 0 && orderType !== newOrderType) {
      setUnsavedChangesModal({ open: true, action: Action.ChangeOrderType, actionValue: newOrderType.toString() });
      return;
    }
    setOrderType(newOrderType);
    setLoading(true);
    const supplier = suppliers && suppliers.length > 0 ? suppliers[0].number : '';
    setSupplier(supplier);
    await fetchCategories(newOrderType, newOrderType === OrderTypeEnum.DirectStoreDelivery ? supplier : '');
    setOrderFormUpdates({
      storeNumber: selectedStore?.storeNumber ?? '',
      orderTypeId: newOrderType,
      supplierNumber: newOrderType === OrderTypeEnum.DirectStoreDelivery ? supplier : '',
      items: [],
      dates: [],
    });
    await fetchOrderForm(newOrderType, -1, 'Name', 'asc', '', supplier, true);
    setLoading(false);
  };

  const canSaveOrder = (): boolean => {
    return !loading && !isAutoShipOrder(orderType);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    itemId: number,
    dateIndex: number,
  ) => {
    
    const value = event.target.value || '0';

    const newQuantity = value.includes('.') ? Math.round(Number(value)) : Math.abs(Number(event.target.value || '0'));
    const fieldKey = `${itemId}-${dateIndex}`; 

    setInputValues((prev) => ({
      ...prev,
      [fieldKey]: newQuantity,
    }));
    console.log('inputvalues');
  };

  const handleInputBlur = (
    itemId: number,
    dateIndex: number,
    sku: string,
  ) => {
    const fieldKey = `${itemId}-${dateIndex}`;
    const newQuantity = inputValues[fieldKey] || 0;
    handleQuantityChange(newQuantity, sku, itemId, dateIndex);
  };

  const handleQuantityChange = (
    newQuantity: number,
    sku: string,
    itemId: number,
    dateIndex: number,
  ) => {
    const date = orderForm?.availableDates.map((x) => x.deliveryDate)[dateIndex];
    const originalItem = orderForm?.categories
      .flatMap((category) => category.items)
      .find((item) => item.id === itemId);
    const originalQuantity = originalItem ? originalItem.quantity[dateIndex] : 0;

    setOrderFormUpdates((prevState) => {
      const itemsCopy = [...prevState.items];
      const itemIndex = itemsCopy.findIndex((item) => item.id === itemId);

      if (itemIndex === -1) {
        if (newQuantity !== originalQuantity && newQuantity >= 0) {
          itemsCopy.push({
            id: itemId,
            sku: sku,
            dates: [{ date: date!, quantity: newQuantity }],
          });
        }
      } else {
        const datesCopy = [...itemsCopy[itemIndex].dates];
        const dateIndexInDates = datesCopy.findIndex((d) => d.date === date);

        if (dateIndexInDates === -1) {
          if (newQuantity !== originalQuantity && newQuantity >= 0) {
            datesCopy.push({ date: date!, quantity: newQuantity });
          }
        } else {
          if (newQuantity === originalQuantity) {
            datesCopy.splice(dateIndexInDates, 1);
            if (datesCopy.length === 0) {
              itemsCopy.splice(itemIndex, 1);
            }
          } else {
            datesCopy[dateIndexInDates] = { ...datesCopy[dateIndexInDates], quantity: newQuantity };
          }
        }

        if (datesCopy.length > 0) {
          itemsCopy[itemIndex] = { ...itemsCopy[itemIndex], dates: datesCopy };
        }
      }

      const unsavedChanges =
      itemsCopy.length !== prevState.items.length ||
      itemsCopy.some((item, index) => item !== prevState.items[index]);

      if (unsavedChanges) {
        return { ...prevState, items: itemsCopy };
      } else {
        return prevState;
      }
    });
  };

  const handleSort = async (direction: string) => {
    const isAsc = direction === 'asc';
    setOrder(isAsc ? 'asc' : 'desc');
  };

  const handleOrderBy = async (property: string) => {
    setOrderBy(property);
  };

  const handleDownloadOrderForm = async () => {
    setLoading(true);
    try {
      const result = await client.downloadOrderForm(selectedStore?.storeNumber, orderType, orderType === OrderTypeEnum.DirectStoreDelivery ? supplier : '');
      if (result && result.success) {
        const blob = base64ToBlob(result.data.fileData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = result.data.fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      handleSnackbarOpen(t('downloadFailed') + ' ' + error, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      await uploadFile(file);
    } else {
      handleSnackbarOpen(t('noFileSelected'), 'warning');
    }
  };

  const uploadFile = async (file: File) => {
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const base64 = e.target?.result?.toString().split(',')[1] ?? '';
        const fileRequest = {
          fileData: base64,
          fileName: file.name,
        };
        setLoading(true);
        const response = await client.uploadOrderForm(selectedStore?.storeNumber, orderType, orderType === OrderTypeEnum.DirectStoreDelivery ? supplier : '', fileRequest);
        if (response && response.success) {
          handleSnackbarOpen(t('uploadSuccess'), 'success');
          await fetchOrderForm(orderType, category, orderBy, order, productSearch, supplier, true);
          setLoading(false);
        } else {
          handleSnackbarOpen(t('uploadFailed'), 'error');
        }

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      };
      reader.onerror = (error) => {
        handleSnackbarOpen(t('fileReadError'), 'error');
        setLoading(false);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      handleSnackbarOpen(t('fileUploadError') + ' ' + error, 'error');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleSnackbarOpen = (message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSupplierChange = async (number: string) => {
    setSupplier(number);
    setLoading(true);
    await fetchCategories(orderType, number);
    await fetchOrderForm(orderType, -1, 'Name', 'asc', '', number, true);
    setLoading(false);
  };

  const handleLeavePageModalClose = () => {
    if (blocker?.reset) {
      blocker.reset();
    }
  };

  const handleLeavePageModalConfirm = async () => {
    await handleSave();
    if (blocker?.proceed) {
      blocker.proceed();
    }
  };

  const handleLeavePageModalCancel = () => {
    if (blocker?.proceed) {
      blocker.proceed();
    }
  };

  const handleUnsavedChangesModalClose = () => {
    setUnsavedChangesModal({ open: false, action: Action.None, actionValue: '' });
  };

  const handleUnsavedChangesModalConfirm = (action?: Action, actionValue?: string) => {
    if (action) {
      switch (action) {
      case Action.ChangeOrderType:
        if (actionValue) {
          setUnsavedChangesModal({ open: false, action: Action.None, actionValue: '' });
          handleOrderTypeChange(actionValue);
        }
        break;
      default:
        setUnsavedChangesModal({ open: false, action: Action.None, actionValue: '' });
      }
    }
  };

  const handleShowPredictedQuantitiesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowPredictedQuantities(event.target.checked);
  };

  const handleMagicWandClicked = (predictedQuantity: number, item: OrderFormItem, index: number) => {
    const fieldKey = `${item.id}-${index}`; 

    setInputValues((prev) => ({
      ...prev,
      [fieldKey]: predictedQuantity,
    }));
    handleQuantityChange(predictedQuantity, item.sku, item.id, index);
  };

  const matchPredictedQuantities = () => {
    setInputValues((prev) => {
      const newValues = JSON.parse(JSON.stringify(prev)) as Record<string, number>;
      orderForm?.availableDates.forEach((_, idx) => {

        predictedDemands.forEach(p => {
          const predictedDemandItem = p.predictedDemandItems[idx];
          const originalItem = orderForm?.categories.flatMap(category => category.items).find(item => item.sku === p.itemNumber);
          const fieldKey = `${originalItem?.id}-${idx}`;
          const originalQuantity = originalItem?.quantity[idx] ?? 0;
          const quantity = predictedDemandItem.hasPrediction ? predictedDemandItem.quantity : originalQuantity;
          newValues[fieldKey] = quantity;
        });
      });

      return newValues;
    });

    setOrderFormUpdates(prevState => {
      const itemsCopy = JSON.parse(JSON.stringify(prevState.items)) as OrderFormItemUpdate[];

      predictedDemands.forEach(x => {
        const index = itemsCopy.findIndex(i => i.sku === x.itemNumber);
        const originalItem = orderForm?.categories.flatMap(category => category.items).find(item => item.sku === x.itemNumber);
        const currentItem = itemsCopy.find(i => i.id === originalItem?.id);

        const newDates = orderForm?.availableDates.map((date, idx) => {

          const existingItem = currentItem?.dates.find(i => {
            const itemDate = i.date.split('T')[0];
            return itemDate === date.deliveryDate;
          });

          const currentValue = existingItem?.quantity ?? originalItem?.quantity[idx];
          const predictedDemandItem = x.predictedDemandItems[idx];
          const quantity = predictedDemandItem.hasPrediction ? predictedDemandItem.quantity : currentValue;

          const newDate: OrderFormItemUpdateDate = {
            date: date.deliveryDate,
            quantity: quantity ?? 0,
          };
          return newDate;
        });

        if (index < 0) {
          const newItem: OrderFormItemUpdate = {
            id: originalItem?.id ?? 0,
            sku: x.itemNumber,
            dates: newDates ?? [],
          };

          itemsCopy.push(newItem);
        }
        else {
          itemsCopy[index].dates = newDates ?? [];
        }
      });

      const unsavedChanges = itemsCopy.length !== prevState.items.length || itemsCopy.some((item, index) => item !== prevState.items[index]);

      if (unsavedChanges) {
        return { ...prevState, items: itemsCopy };
      }
      else {
        return prevState;
      }
    });
  };

  const renderQuantityCell = (item: OrderFormItem, index: number, categoryId: number) => {
    const initialQuantity = item.quantity[index].toString();
    const podItem = predictedDemands.find(x => x.itemNumber === item.sku);
    const predictedItem = podItem?.predictedDemandItems[index];
    const hasPrediction = predictedItem?.hasPrediction;
    const predictedQuantity = hasPrediction ? predictedItem.quantity : 0;
    const currentValue = orderFormUpdates.items.find(updateItem => updateItem.id === item.id)?.dates.find(d => d.date === orderForm?.availableDates.map(x=>x.deliveryDate)[index])?.quantity.toString() ?? initialQuantity ?? '0';
    const matchesPredictedValue = predictedQuantity.toString() === currentValue;
    const showPrediction = showPredictedQuantities && hasPrediction;
    const borderColor = showPrediction && matchesPredictedValue ? theme.palette.success[400] : '';
    const maxOrderQuantity = podItem?.predictionInfo?.maxOrderQuantity;
    const safetyStock = podItem?.predictionInfo?.safetyStock;
    const predictedDemandQuantity = predictedItem?.predictedDemandQuantity;
    const fieldKey = `${item.id}-${index}`;
    const localValue = inputValues[fieldKey] ?? currentValue;

    return (
      <TableCell key={`categoryItemAvailableDate-${categoryId}-${item.id}-${index}`}
        align="center"
        style={{
          backgroundColor: 'inherit',
        }}
      >
        <Box className="quantity-input-container"
          sx={{ display: 'flex', position: 'relative' }}>
          <TextField
            label={showPrediction ? t('predictedQuantity', { value: predictedQuantity.toString() }) : null}
            error={isQuantityError(item, currentValue)}
            helperText={quantityError(item, currentValue)}
            disabled={!canSaveOrder()}
            autoComplete='off'
            type='number'
            style={{ width: '100%', minWidth: 50, height: 50, textAlign: 'center' }}
            fullWidth
            onFocus={(e) => e.target.addEventListener('wheel', (e) => { e.preventDefault(); }, { passive: false })}
            value={localValue}
            onChange={(event) => handleInputChange(event, item.id, index)}
            onBlur={() => handleInputBlur(item.id, index, item.sku)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: borderColor,
                },
              },

              '& .MuiInputLabel-root': {
                color: borderColor,
              },
            }}
          ></TextField> 
          {
            showPrediction &&
            <Tooltip title={
              <span>
                {
                  predictedDemandQuantity != null && (
                    <>
                      {t('predictedDemandQuantity', { value: predictedDemandQuantity, uom: item.primaryUom })}
                      <br />
                    </>
                  )
                }
                {
                  maxOrderQuantity != null && (
                    <>
                      {t('maxOrderQuantity', { value: maxOrderQuantity, uom: item.primaryUom })}
                      <br />
                    </>
                  )
                }
                {safetyStock != null && t('safetyStock', { value: safetyStock, uom: item.primaryUom })}
              </span>
            }>
              <IconButton onClick={() => handleMagicWandClicked(predictedQuantity, item, index)}>
                <MagicWand01
                  color={matchesPredictedValue ? theme.palette.success[700] : theme.palette.custom.gray[400] }/>
              </IconButton>
            </Tooltip>
          }
        </Box>
      </TableCell>
    );
  };

  const quantityError = (item: OrderFormItem, currentValue: string): string | null => {
    const quantity = Number(currentValue);

    if(quantity === 0){
      return null;
    }

    if ((item.maxQuantity != null) && quantity > item.maxQuantity) {
      return `${t('maxQuantityAllowedIs')}: ${item.maxQuantity}`;
    }

    if ((item.minQuantity != null) && quantity < item.minQuantity)
      return `${t('minQuantityAllowedIs')}: ${item.minQuantity}`;

    return null;
  };

  const isQuantityError = (item: OrderFormItem, currentValue: string): boolean => {
    return quantityError(item, currentValue) != null;
  };

  const getCurrentValue = (item: OrderFormItem, index: number) => {
    const initialQuantity = item.quantity[index].toString();
    const currentValue = orderFormUpdates.items.find(updateItem => updateItem.id === item.id)?.dates.find(d => d.date === orderForm?.availableDates.map(x => x.deliveryDate)[index])?.quantity.toString() ?? initialQuantity ?? '0';
    return currentValue;
  };

  const anyErrors = (): boolean => {
    return orderForm?.availableDates.some((date, index) => checkForErrors(index)) ?? false;
  };

  const checkForErrors = (index: number): boolean => {
    return orderForm?.categories.some(category => checkCategoryErrors(category, index)) ?? false;
  };

  const checkCategoryErrors = (category: OrderFormCategory, index: number): boolean => {
    return category.items.some(item => isQuantityError(item, getCurrentValue(item, index)));
  };

  const fixQuantities = () => {
    setOrderFormUpdates((prev) => {
      const updatedItems = prev.items.map((item) => {
        const adjustedDates = adjustItemQuantities(item);
        return { ...item, dates: adjustedDates };
      });

      return { ...prev, items: updatedItems };
    });
  };

  const adjustItemQuantities = (item: OrderFormItemUpdate) => {
    const matchingItem = findMatchingItem(item.id);
    const maxQuant = matchingItem?.maxQuantity;
    const minQuant = matchingItem?.minQuantity;

    return item.dates.map((date) => adjustDateQuantity(date, maxQuant, minQuant));
  };

  const findMatchingItem = (itemId: number) => {
    const allItems = orderForm?.categories.flatMap(x => x.items);
    return allItems?.find(item => item.id === itemId);
  };

  const adjustDateQuantity = (date: OrderFormItemUpdateDate, maxQuant: number | undefined, minQuant: number | undefined) => {
    let newQuantity = date.quantity;

    if(newQuantity != 0){
      if (maxQuant && newQuantity > maxQuant) newQuantity = maxQuant;
      if (minQuant && newQuantity < minQuant) newQuantity = minQuant;
    }
    
    return { ...date, quantity: newQuantity };
  };

  const autocompleteOptions = [{ id: -1, name: 'All', parentName: '' }, ...(Array.isArray(categories) ? categories : [])];

  const getOptionLabel = (option: { parentName?: string; name: string }): string => {
    const parentName = option.parentName;
    const categoryName = option.name;

    const translatedParent = parentName && isOrderCategoryParent(parentName)
      ? t(OrdersCategoriesParentsMap[parentName as keyof typeof OrdersCategoriesParentsMap])
      : parentName;

    const translatedCategory = isOrderCategory(categoryName)
      ? t(OrderCategoriesTranslationMap[categoryName as keyof typeof OrderCategoriesTranslationMap])
      : categoryName;

    if (parentName) {
      return translatedParent
        ? `${translatedParent} > ${translatedCategory}`
        : `${parentName} > ${translatedCategory}`;
    } else {
      return translatedCategory;
    }
  };

  return (
    <PageArea>
      <Grid item
        mb={10}
        sx={{ textAlign: 'left' }}>
        {
          <BackButton
            handleClick={handleExitAndSave}
            title={t('exitAndSave')}
            isLink={false}
          />
        }
      </Grid>
      <Grid container>
        <Grid item
          container
          justifyContent={'space-between'}
          alignItems={'end'}
          xs={12}
          md={12}>
          <Grid item
            xs={12}
            md={10}>
            <PageTitle>
              {t('title')}
            </PageTitle>
            <Typography sx={{ fontSize: '16px' }}>
              {t('subtitle')}
            </Typography>
          </Grid>
          <Grid item
            xs={12}
            md={2}>
            <Button
              variant="primary"
              size="lg"
              disabled={loading || orderFormUpdates.items.length === 0 || anyErrors()}
              fullWidth
              startIcon={<SaveIcon />}
              onClick={handleSave}>
              {t('save')}
            </Button>
          </Grid>
        </Grid>
        <Grid item
          container
          mt={5}
          xs={12}
          sx={{
            border: '1px solid',
            borderColor: theme.palette.custom.gray[300],
          }}>
          <Grid item
            container
            alignItems={'center'}
            xs={12}
            md={12}
            spacing={4}
            sx={{
              padding: 4,
            }}>
            <Grid item
              xs={12}
              md={orderType === OrderTypeEnum.DirectStoreDelivery ? 2 : 4}>
              <TextField
                fullWidth
                size='small'
                label={t('orderType')}
                value={orderType}
                disabled={loading}
                required
                onChange={(e) => handleOrderTypeChange(e.target.value)}
                select
              >
                {orderTypes?.map((type) => (
                  <MenuItem key={type.id}
                    value={type.id}>
                    {t(OrderTypeTranslationMap[type.name as keyof typeof OrderTypeTranslationMap]) || type.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {orderType === OrderTypeEnum.DirectStoreDelivery &&
              <Grid item
                md={2}
                xs={12}>
                <TextField
                  fullWidth
                  size='small'
                  label={t('supplier')}
                  disabled={loading}
                  value={supplier}
                  required
                  onChange={(e) => handleSupplierChange(e.target.value)}
                  select
                >
                  {suppliers.map((type) => (
                    <MenuItem key={type.id}
                      value={type.number}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            }
            <Grid item
              xs={12}
              md={2}>
              <TextField
                fullWidth
                size='small'
                label={t('orderBy')}
                disabled={loading}
                value={orderBy}
                onChange={(e) => handleOrderBy(e.target.value)}
                required
                select
              >
                <MenuItem value='Name'>
                  {t('productName')}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item
              xs={12}
              md={2}>
              <TextField
                fullWidth
                size='small'
                label="Direction"
                disabled={loading}
                value={order}
                onChange={(e) => handleSort(e.target.value)}
                required
                select
              >
                <MenuItem value='asc'>
                  {t('ascending')}
                </MenuItem>
                <MenuItem value='desc'>
                  {t('descending')}
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item
              xs={12}
              md={2}>
              <Button fullWidth
                variant="secondary"
                disabled={loading}
                size="lg"
                onClick={handleClearFilters}>
                {t('clearFilters')}
              </Button>
            </Grid>
            <Grid item
              xs={12}
              md={2}>
              <Button
                fullWidth
                variant="secondary"
                disabled={loading || !canSaveOrder()}
                startIcon={<DownloadIcon />}
                size="lg"
                onClick={handleDownloadOrderForm}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {t('downloadOrderForm')}
              </Button>
            </Grid>
            <Grid item
              xs={12}
              md={4}>
              <TextField
                fullWidth
                size='small'
                disabled={loading}
                placeholder={t('searchByItemNameOrSKU')}
                value={productSearch}
                onChange={(e) => setProductSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon><SearchSm /></SvgIcon>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item
              xs={12}
              md={4}>
              <Autocomplete
                size='small'
                options={autocompleteOptions}
                disabled={loading}
                getOptionLabel={getOptionLabel}
                value={categories.find(cat => cat.id === category) ?? autocompleteOptions[0]}
                onChange={(event, newValue) => {
                  setCategory(newValue ? newValue.id : -1);
                }}
                renderInput={(params) => (
                  <TextField {...params}
                    label={t('category')}
                    placeholder={t('choose')} />
                )}
              />
            </Grid>
            <Grid item
              xs={12}
              md={2}>
              <Button fullWidth
                variant="primary"
                disabled={loading}
                size="lg"
                startIcon={<SearchSm />}
                onClick={handleSearch}>
                {t('search')}
              </Button>
            </Grid>
            <Grid item
              xs={12}
              md={2}>
              <Button
                fullWidth
                variant="secondary"
                disabled={loading || !canSaveOrder()}
                startIcon={<UploadIcon />}
                size="lg"
                onClick={handleUploadButtonClick}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {t('uploadOrderForm')}
              </Button>
              <input
                type="file"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileSelect}
                accept=".xlsx, .xls"
              />
            </Grid>
            {
              isPodEnabled && 
              <>
                <Grid item
                  xs={12}
                  md={4}
                  p={5}>
                  <FormControlLabel control={
                    <Switch checked={showPredictedQuantities}
                      onChange={handleShowPredictedQuantitiesChange}/>
                  }
                  label={t('showPredictedQuantities')} />
                </Grid>

                { 
                  showPredictedQuantities && 
                  <Grid item
                    xs={12}
                    md={4}
                    p={5}>
                    <Button fullWidth
                      variant="primary"
                      disabled={loading}
                      startIcon={<MagicWand01 />}
                      size="lg"
                      onClick={matchPredictedQuantities}>
                      {t('matchPredictedQuantities')}
                    </Button>
                  </Grid>
                }
              </>
            }
            
          </Grid>
          <Grid item
            xs={12}
            md={12}
            mt={5}
            mb={5}>
            <Divider />
          </Grid>
          <Grid item
            xs={12}
            py={5}>
            {loading ? <LoadingWheel /> :
              <TableContainer component={'div'}>
                <Table
                  sx={{ tableLayout: 'fixed', minWidth: 900, overflow: 'hidden' }}
                  size="small"
                  aria-label="a table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: showImage ? '40%' : '5%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold' }} >
                        {t('productInformation')}
                      </TableCell>
                      {orderForm?.availableDates?.map((item, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            width: showImage ? '8.5%' : '12.5%',
                            fontWeight: 'bold',
                            padding: { xs: '2px', sm: '4px', md: '6px' },
                            whiteSpace: 'normal',
                            wordBreak: !showImage ? 'break-word' : '',
                            overflow: 'hidden',
                          }}
                          align="center"
                        >
                          <Box>
                            <Typography component="span"
                              sx={{ fontWeight: 'bold', fontSize: '12px' }}>
                              {t('deliveryDate')}
                            </Typography>
                            <Typography component="span"
                              display="block"
                              sx={{ fontSize: '12px' }}>
                              {item.deliveryDate}
                            </Typography>
                            <Typography component="span"
                              sx={{ fontWeight: 'bold', mt: 1, fontSize: '12px' }}>
                              {t('cutOff')}
                            </Typography>
                            <Typography component="span"

                              sx={{ fontSize: '12px' }}>
                              {item.cutOffTime}
                            </Typography>
                          </Box>
                        </TableCell>
                      ))
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!!orderForm && orderForm?.categories?.map((category) => (
                      <>
                        <TableRow key={`category-${category.id}`}>
                          <TableCell colSpan={orderForm?.availableDates.length + 4}
                            style={{ backgroundColor: theme.palette.primary.main }}>
                            <Typography variant="textSM"
                              color="white">
                              {isOrderCategory(category.name) ? t(OrderCategoriesTranslationMap[category.name as keyof typeof OrderCategoriesTranslationMap]) : category.name}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {category?.items?.map((item) => (
                          <TableRow key={`categoryItem-${category.id}-${item.id}`}
                            onFocus={() => handleItemFocus(item.id)}
                          >
                            <TableCell sx={{ width: '40%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              <Grid container
                                alignItems={'center'}
                                spacing={1}
                                wrap="nowrap">
                                <Grid item
                                  sx={{ width: 'auto', flexShrink: 1 }}
                                >
                                  <Box
                                    sx={{
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Box
                                      component="img"
                                      src={item.thumbnailUrl ? item.thumbnailUrl : 'https://placehold.co/150x150'}
                                      alt="item-thumbnail"
                                      loading="lazy"
                                      sx={{
                                        objectFit: 'cover',
                                        width: '100%',
                                        maxWidth: { xs: '75px', sm: '100px' },
                                        height: isLargeScreen ? 'auto' : '150px',
                                        borderRadius: '8px',
                                        border: '1px solid #ddd',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                      }}
                                    />
                                  </Box>
                                </Grid>
                                <Grid item
                                  sx={{ minWidth: 0, paddingLeft: '10px !important', flex: 1 }}>
                                  {isLargeScreen ? (
                                    <Grid container
                                      spacing={2}>
                                      <Grid item
                                        xs={6}>
                                        <TextWithOverflowTooltip text={item.name} />
                                        <TextWithOverflowTooltip text={item.sku} />
                                        <TextWithOverflowTooltip text={item.category} />
                                        {(showMessageWhenNoCost || item.price === 0) ?
                                          <TextWithOverflowTooltip text='' />
                                          :
                                          <TextWithOverflowTooltip text={applyRoundAndFormat(item.price, currencyCode)} />
                                        }

                                      </Grid>
                                      <Grid item
                                        xs={6}>
                                        <TextWithOverflowTooltip text={`${t('orderableUOM')} ${item.orderableUom} (${item.packSize}${item.primaryUom})`} />
                                        <TextWithOverflowTooltip text={`${t('primaryUOM')} ${item.primaryUom}`} />
                                        <Tooltip title={`${t('stockOnHand')}: ${item.stockOnHand}`}
                                          arrow>
                                          <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                                            {t('stockOnHandABR')} {item.stockOnHand}
                                          </Box>
                                        </Tooltip>
                                        <Tooltip title={`${t('stockOnOrder')}: ${item.stockOnOrder}`}
                                          arrow>
                                          <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                                            {t('StockOnOrderABR')} {item.stockOnOrder}
                                          </Box>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    <>
                                      {item.name}  <br />
                                      {item.sku}   <br />
                                      {item.category}   <br />
                                      {(showMessageWhenNoCost || item.price === 0) ?
                                        '' :
                                        applyRoundAndFormat(item.price, currencyCode)
                                      }
                                      <br />
                                      {t('orderableUOM')} {item.orderableUom} ({item.packSize}{item.primaryUom})  <br />
                                      {t('primaryUOM')} {item.primaryUom}  <br />
                                      <Tooltip title={`${t('stockOnHandr')}: ${item.stockOnOrder}`}
                                        arrow>
                                        <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                                          {t('stockOnHandABR')} {item.stockOnOrder}
                                        </Box>
                                      </Tooltip>
                                      <Tooltip title={`${t('stockOnOrder')}: ${item.stockOnOrder}`}
                                        arrow>
                                        <Box style={{ whiteSpace: 'nowrap', overflow: 'visible', textOverflow: 'clip' }}>
                                          {t('StockOnOrderABR')} {item.stockOnOrder}
                                        </Box>
                                      </Tooltip>   <br />
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </TableCell>
                            {orderForm.availableDates.map(x => x.deliveryDate).map((date, index) => {
                              return (
                                renderQuantityCell(item, index, category.id)
                              );
                            })}
                          </TableRow>
                        ))}
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </Grid>
        </Grid>
        <Grid item
          container
          justifyContent={'space-between'}
          alignItems={'end'}
          xs={12}
          md={12}>
          <Grid item
            xs={12}
            md={2}
            mt={'10px'}>
            <Button
              variant="primary"
              size="lg"
              disabled={loading || orderFormUpdates.items.length === 0 || anyErrors() || !canSaveOrder()}
              fullWidth
              startIcon={<SaveIcon />}
              onClick={handleSave}>
              {t('save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={
          snackbar.message === t('autosaving') ? null : 3000
        }
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <LeavePageModal
        open={blocker.state === 'blocked' && orderFormUpdates.items.length > 0 && !isExitAndSaveClicked}
        onConfirm={handleLeavePageModalConfirm}
        onClose={handleLeavePageModalClose}
        onCancel={handleLeavePageModalCancel}
        onConfirmUrl='/store/orders-history'
        leaveWarningContent={t('saveOnLeaveWarningContent')}
        leaveWarningTitle={t('saveOnLeaveWarningTitle')}
      />
      <ReloadPageModal
        open={unsavedChangesModal?.open ?? false}
        onConfirm={() => handleUnsavedChangesModalConfirm(unsavedChangesModal?.action, unsavedChangesModal?.actionValue)}
        onClose={handleUnsavedChangesModalClose} />
      <QuantityWarningDialog
        setShowQuantityWarningDialog={setShowQuantityWarningDialog}
        message={warningMessage}
        showDialog={showQuantityWarningDialog} />
    </PageArea>
  );
};

export default OrderFormPage;
