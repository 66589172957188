import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import AUTHENTICATION_EN from './assets/locales/en/authentication.json';
import AUTHENTICATION_DE from './assets/locales/de/authentication.json';
import AUTHENTICATION_ES from './assets/locales/es/authentication.json';
import AUTHENTICATION_FR from './assets/locales/fr/authentication.json';
import AUTHENTICATION_IT from './assets/locales/it/authentication.json';
import AUTHENTICATION_RO from './assets/locales/ro/authentication.json';
import AUTHENTICATION_PL from './assets/locales/pl/authentication.json';
import AUTHENTICATION_CZ from './assets/locales/cz/authentication.json';
import AUTHENTICATION_SK from './assets/locales/sk/authentication.json';

import COMMON_EN from './assets/locales/en/common.json';
import COMMON_DE from './assets/locales/de/common.json';
import COMMON_ES from './assets/locales/es/common.json';
import COMMON_FR from './assets/locales/fr/common.json';
import COMMON_IT from './assets/locales/it/common.json';
import COMMON_RO from './assets/locales/ro/common.json';
import COMMON_PL from './assets/locales/pl/common.json';
import COMMON_CZ from './assets/locales/cz/common.json';
import COMMON_SK from './assets/locales/sk/common.json';

import CREDITDREQUEST_EN from './assets/locales/en/createCreditRequest.json'; 
import CREDITREQUEST_DE from './assets/locales/de/createCreditRequest.json'; 
import CREDITREQUEST_ES from './assets/locales/es/createCreditRequest.json'; 
import CREDITREQUEST_FR from './assets/locales/fr/createCreditRequest.json';
import CREDITREQUEST_IT from './assets/locales/it/createCreditRequest.json'; 
import CREDITREQUEST_PL from './assets/locales/pl/createCreditRequest.json'; 
import CREDITREQUEST_RO from './assets/locales/ro/createCreditRequest.json'; 
import CREDITREQUEST_CZ from './assets/locales/cz/createCreditRequest.json'; 
import CREDITREQUEST_SK from './assets/locales/sk/createCreditRequest.json'; 

import CREATESTOCKCOUNT_EN from './assets/locales/en/createStockCount.json';
import CREATESTOCKCOUNT_DE from './assets/locales/de/createStockCount.json';
import CREATESTOCKCOUNT_ES from './assets/locales/es/createStockCount.json';
import CREATESTOCKCOUNT_FR from './assets/locales/fr/createStockCount.json';
import CREATESTOCKCOUNT_IT from './assets/locales/it/createStockCount.json';
import CREATESTOCKCOUNT_PL from './assets/locales/pl/createStockCount.json';
import CREATESTOCKCOUNT_RO from './assets/locales/ro/createStockCount.json';
import CREATESTOCKCOUNT_CZ from './assets/locales/cz/createStockCount.json';
import CREATESTOCKCOUNT_SK from './assets/locales/sk/createStockCount.json';

import CREATESTOCKCOUNTSCHEDULE_EN from './assets/locales/en/createStockCountSchedule.json';
import CREATESTOCKCOUNTSCHEDULE_DE from './assets/locales/de/createStockCountSchedule.json';
import CREATESTOCKCOUNTSCHEDULE_ES from './assets/locales/es/createStockCountSchedule.json';
import CREATESTOCKCOUNTSCHEDULE_FR from './assets/locales/fr/createStockCountSchedule.json';
import CREATESTOCKCOUNTSCHEDULE_IT from './assets/locales/it/createStockCountSchedule.json';
import CREATESTOCKCOUNTSCHEDULE_PL from './assets/locales/pl/createStockCountSchedule.json';
import CREATESTOCKCOUNTSCHEDULE_RO from './assets/locales/ro/createStockCountSchedule.json';
import CREATESTOCKCOUNTSCHEDULE_CZ from './assets/locales/cz/createStockCountSchedule.json';
import CREATESTOCKCOUNTSCHEDULE_SK from './assets/locales/sk/createStockCountSchedule.json';

import CREATETRANSACTION_EN from './assets/locales/en/createTransaction.json';
import CREATETRANSACTION_DE from './assets/locales/de/createTransaction.json';
import CREATETRANSACTION_ES from './assets/locales/es/createTransaction.json';
import CREATETRANSACTION_FR from './assets/locales/fr/createTransaction.json';
import CREATETRANSACTION_IT from './assets/locales/it/createTransaction.json';
import CREATETRANSACTION_RO from './assets/locales/ro/createTransaction.json';
import CREATETRANSACTION_PL from './assets/locales/pl/createTransaction.json';
import CREATETRANSACTION_CZ from './assets/locales/cz/createTransaction.json';
import CREATETRANSACTION_SK from './assets/locales/sk/createTransaction.json';

import DRAWER_EN from './assets/locales/en/drawer.json';
import DRAWER_DE from './assets/locales/de/drawer.json';
import DRAWER_ES from './assets/locales/es/drawer.json';
import DRAWER_FR from './assets/locales/fr/drawer.json';
import DRAWER_IT from './assets/locales/it/drawer.json';
import DRAWER_RO from './assets/locales/ro/drawer.json';
import DRAWER_PL from './assets/locales/pl/drawer.json';
import DRAWER_CZ from './assets/locales/cz/drawer.json';
import DRAWER_SK from './assets/locales/sk/drawer.json';

import ERROR_PAGE_EN from './assets/locales/en/errorPage.json';
import ERROR_PAGE_DE from './assets/locales/de/errorPage.json';
import ERROR_PAGE_ES from './assets/locales/es/errorPage.json';
import ERROR_PAGE_FR from './assets/locales/fr/errorPage.json';
import ERROR_PAGE_IT from './assets/locales/it/errorPage.json';
import ERROR_PAGE_PL from './assets/locales/pl/errorPage.json';
import ERROR_PAGE_RO from './assets/locales/ro/errorPage.json';
import ERROR_PAGE_CZ from './assets/locales/cz/errorPage.json';
import ERROR_PAGE_SK from './assets/locales/sk/errorPage.json';

import INVOICEDETAILS_EN from './assets/locales/en/invoiceDetails.json';
import INVOICEDETAILS_DE from './assets/locales/de/invoiceDetails.json';
import INVOICEDETAILS_FR from './assets/locales/fr/invoiceDetails.json';
import INVOICEDETAILS_ES from './assets/locales/es/invoiceDetails.json';
import INVOICEDETAILS_IT from './assets/locales/it/invoiceDetails.json';
import INVOICEDETAILS_PL from './assets/locales/pl/invoiceDetails.json';
import INVOICEDETAILS_RO from './assets/locales/ro/invoiceDetails.json';
import INVOICEDETAILS_CZ from './assets/locales/cz/invoiceDetails.json';
import INVOICEDETAILS_SK from './assets/locales/sk/invoiceDetails.json';

import INVOICES_EN from './assets/locales/en/invoices.json';
import INVOICES_DE from './assets/locales/de/invoices.json';
import INVOICES_ES from './assets/locales/es/invoices.json';
import INVOICES_FR from './assets/locales/fr/invoices.json';
import INVOICES_IT from './assets/locales/it/invoices.json';
import INVOICES_PL from './assets/locales/pl/invoices.json';
import INVOICES_RO from './assets/locales/ro/invoices.json';
import INVOICES_CZ from './assets/locales/cz/invoices.json';
import INVOICES_SK from './assets/locales/sk/invoices.json';

import MANAGE_NOTIFICATIONS_EN from './assets/locales/en/manageNotifications.json';
import MANAGE_NOTIFICATIONS_DE from './assets/locales/de/manageNotifications.json';
import MANAGE_NOTIFICATIONS_ES from './assets/locales/es/manageNotifications.json';
import MANAGE_NOTIFICATIONS_FR from './assets/locales/fr/manageNotifications.json';
import MANAGE_NOTIFICATIONS_IT from './assets/locales/it/manageNotifications.json';
import MANAGE_NOTIFICATIONS_PL from './assets/locales/pl/manageNotifications.json';
import MANAGE_NOTIFICATIONS_RO from './assets/locales/ro/manageNotifications.json';
import MANAGE_NOTIFICATIONS_CZ from './assets/locales/cz/manageNotifications.json';
import MANAGE_NOTIFICATIONS_SK from './assets/locales/sk/manageNotifications.json';

import MANAGE_CONFIGURATIONS_EN from './assets/locales/en/manageConfigurations.json';
import MANAGE_CONFIGURATIONS_CZ from './assets/locales/cz/manageConfigurations.json';
import MANAGE_CONFIGURATIONS_DE from './assets/locales/de/manageConfigurations.json';
import MANAGE_CONFIGURATIONS_ES from './assets/locales/es/manageConfigurations.json';
import MANAGE_CONFIGURATIONS_FR from './assets/locales/fr/manageConfigurations.json';
import MANAGE_CONFIGURATIONS_IT from './assets/locales/it/manageConfigurations.json';
import MANAGE_CONFIGURATIONS_PL from './assets/locales/pl/manageConfigurations.json';
import MANAGE_CONFIGURATIONS_RO from './assets/locales/ro/manageConfigurations.json';
import MANAGE_CONFIGURATIONS_SK from './assets/locales/sk/manageConfigurations.json';

import MANAGE_POD_CONFIGURATIONS_EN from './assets/locales/en/managePodConfigurations.json';
import MANAGE_POD_CONFIGURATIONS_DE from './assets/locales/de/managePodConfigurations.json';
import MANAGE_POD_CONFIGURATIONS_ES from './assets/locales/es/managePodConfigurations.json';
import MANAGE_POD_CONFIGURATIONS_FR from './assets/locales/fr/managePodConfigurations.json';
import MANAGE_POD_CONFIGURATIONS_PL from './assets/locales/pl/managePodConfigurations.json';
import MANAGE_POD_CONFIGURATIONS_IT from './assets/locales/it/managePodConfigurations.json';
import MANAGE_POD_CONFIGURATIONS_RO from './assets/locales/ro/managePodConfigurations.json';
import MANAGE_POD_CONFIGURATIONS_CZ from './assets/locales/cz/managePodConfigurations.json';
import MANAGE_POD_CONFIGURATIONS_SK from './assets/locales/sk/managePodConfigurations.json';

import MANAGE_ROLES_EN from './assets/locales/en/manageRoles.json';
import MANAGE_ROLES_DE from './assets/locales/de/manageRoles.json';
import MANAGE_ROLES_ES from './assets/locales/es/manageRoles.json';
import MANAGE_ROLES_FR from './assets/locales/fr/manageRoles.json';
import MANAGE_ROLES_IT from './assets/locales/it/manageRoles.json';
import MANAGE_ROLES_PL from './assets/locales/pl/manageRoles.json';
import MANAGE_ROLES_RO from './assets/locales/ro/manageRoles.json';
import MANAGE_ROLES_CZ from './assets/locales/cz/manageRoles.json';
import MANAGE_ROLES_SK from './assets/locales/sk/manageRoles.json';

import MANAGE_USERS_EN from './assets/locales/en/manageUsers.json';
import MANAGE_USERS_DE from './assets/locales/de/manageUsers.json';
import MANAGE_USERS_ES from './assets/locales/es/manageUsers.json';
import MANAGE_USERS_FR from './assets/locales/fr/manageUsers.json';
import MANAGE_USERS_IT from './assets/locales/it/manageUsers.json';
import MANAGE_USERS_PL from './assets/locales/pl/manageUsers.json';
import MANAGE_USERS_RO from './assets/locales/ro/manageUsers.json';
import MANAGE_USERS_CZ from './assets/locales/cz/manageUsers.json';
import MANAGE_USERS_SK from './assets/locales/sk/manageUsers.json';

import MANAGE_CATEGORIES_EN from './assets/locales/en/manageCategories.json';
import MANAGE_CATEGORIES_DE from './assets/locales/de/manageCategories.json';
import MANAGE_CATEGORIES_ES from './assets/locales/es/manageCategories.json';
import MANAGE_CATEGORIES_FR from './assets/locales/fr/manageCategories.json';
import MANAGE_CATEGORIES_IT from './assets/locales/it/manageCategories.json';
import MANAGE_CATEGORIES_PL from './assets/locales/pl/manageCategories.json';
import MANAGE_CATEGORIES_RO from './assets/locales/ro/manageCategories.json';
import MANAGE_CATEGORIES_CZ from './assets/locales/cz/manageCategories.json';
import MANAGE_CATEGORIES_SK from './assets/locales/sk/manageCategories.json';

import MANAGETRANSACTIONS_EN from './assets/locales/en/manageTransactions.json';
import MANAGETRANSACTIONS_DE from './assets/locales/de/manageTransactions.json';
import MANAGETRANSACTIONS_ES from './assets/locales/es/manageTransactions.json';
import MANAGETRANSACTIONS_FR from './assets/locales/fr/manageTransactions.json';
import MANAGETRANSACTIONS_IT from './assets/locales/it/manageTransactions.json';
import MANAGETRANSACTIONS_PL from './assets/locales/pl/manageTransactions.json';
import MANAGETRANSACTIONS_RO from './assets/locales/ro/manageTransactions.json';
import MANAGETRANSACTIONS_CZ from './assets/locales/cz/manageTransactions.json';
import MANAGETRANSACTIONS_SK from './assets/locales/sk/manageTransactions.json';

import ORDER_DETAILS_EN from './assets/locales/en/orderDetails.json';
import ORDER_DETAILS_DE from './assets/locales/de/orderDetails.json';
import ORDER_DETAILS_ES from './assets/locales/es/orderDetails.json';
import ORDER_DETAILS_FR from './assets/locales/fr/orderDetails.json';
import ORDER_DETAILS_IT from './assets/locales/it/orderDetails.json';
import ORDER_DETAILS_RO from './assets/locales/ro/orderDetails.json';
import ORDER_DETAILS_PL from './assets/locales/pl/orderDetails.json';
import ORDER_DETAILS_CZ from './assets/locales/cz/orderDetails.json';
import ORDER_DETAILS_SK from './assets/locales/sk/orderDetails.json';

import ORDER_FORM_EN from './assets/locales/en/orderForm.json';
import ORDER_FORM_DE from './assets/locales/de/orderForm.json';
import ORDER_FORM_ES from './assets/locales/es/orderForm.json';
import ORDER_FORM_FR from './assets/locales/fr/orderForm.json';
import ORDER_FORM_IT from './assets/locales/it/orderForm.json';
import ORDER_FORM_RO from './assets/locales/ro/orderForm.json';
import ORDER_FORM_PL from './assets/locales/pl/orderForm.json';
import ORDER_FORM_CZ from './assets/locales/cz/orderForm.json';
import ORDER_FORM_SK from './assets/locales/sk/orderForm.json';

import ORDER_HISTORY_EN from './assets/locales/en/orderHistory.json';
import ORDER_HISTORY_DE from './assets/locales/de/orderHistory.json';
import ORDER_HISTORY_ES from './assets/locales/es/orderHistory.json';
import ORDER_HISTORY_FR from './assets/locales/fr/orderHistory.json';
import ORDER_HISTORY_IT from './assets/locales/it/orderHistory.json';
import ORDER_HISTORY_RO from './assets/locales/ro/orderHistory.json';
import ORDER_HISTORY_PL from './assets/locales/pl/orderHistory.json';
import ORDER_HISTORY_CZ from './assets/locales/cz/orderHistory.json';
import ORDER_HISTORY_SK from './assets/locales/sk/orderHistory.json';

import PRODUCT_DETAILS_EN from './assets/locales/en/productDetails.json';
import PRODUCT_DETAILS_DE from './assets/locales/de/productDetails.json';
import PRODUCT_DETAILS_ES from './assets/locales/es/productDetails.json';
import PRODUCT_DETAILS_FR from './assets/locales/fr/productDetails.json';
import PRODUCT_DETAILS_IT from './assets/locales/it/productDetails.json';
import PRODUCT_DETAILS_RO from './assets/locales/ro/productDetails.json';
import PRODUCT_DETAILS_PL from './assets/locales/pl/productDetails.json';
import PRODUCT_DETAILS_CZ from './assets/locales/cz/productDetails.json';
import PRODUCT_DETAILS_SK from './assets/locales/sk/productDetails.json';

import PRODUCTS_EN from './assets/locales/en/products.json';
import PRODUCTS_DE from './assets/locales/de/products.json';
import PRODUCTS_ES from './assets/locales/es/products.json';
import PRODUCTS_FR from './assets/locales/fr/products.json';
import PRODUCTS_IT from './assets/locales/it/products.json';
import PRODUCTS_RO from './assets/locales/ro/products.json';
import PRODUCTS_PL from './assets/locales/pl/products.json';
import PRODUCTS_CZ from './assets/locales/cz/products.json';
import PRODUCTS_SK from './assets/locales/sk/products.json';

import PURCHASEORDERDETAILS_EN from './assets/locales/en/purchaseOrderDetails.json';
import PURCHASEORDERDETAILS_IT from './assets/locales/it/purchaseOrderDetails.json';
import PURCHASEORDERDETAILS_DE from './assets/locales/de/purchaseOrderDetails.json';
import PURCHASEORDERDETAILS_ES from './assets/locales/es/purchaseOrderDetails.json';
import PURCHASEORDERDETAILS_FR from './assets/locales/fr/purchaseOrderDetails.json';
import PURCHASEORDERDETAILS_RO from './assets/locales/ro/purchaseOrderDetails.json';
import PURCHASEORDERDETAILS_PL from './assets/locales/pl/purchaseOrderDetails.json';
import PURCHASEORDERDETAILS_CZ from './assets/locales/cz/purchaseOrderDetails.json';
import PURCHASEORDERDETAILS_SK from './assets/locales/sk/purchaseOrderDetails.json';

import QUICK_ORDER_EN from './assets/locales/en/quickOrder.json';
import QUICK_ORDER_DE from './assets/locales/de/quickOrder.json';
import QUICK_ORDER_ES from './assets/locales/es/quickOrder.json';
import QUICK_ORDER_FR from './assets/locales/fr/quickOrder.json';
import QUICK_ORDER_IT from './assets/locales/it/quickOrder.json';
import QUICK_ORDER_RO from './assets/locales/ro/quickOrder.json';
import QUICK_ORDER_PL from './assets/locales/pl/quickOrder.json';
import QUICK_ORDER_CZ from './assets/locales/cz/quickOrder.json';
import QUICK_ORDER_SK from './assets/locales/sk/quickOrder.json';

import RECIPE_EN from './assets/locales/en/recipe.json';
import RECIPE_DE from './assets/locales/de/recipe.json';
import RECIPE_ES from './assets/locales/es/recipe.json';
import RECIPE_FR from './assets/locales/fr/recipe.json';
import RECIPE_IT from './assets/locales/it/recipe.json';
import RECIPE_PL from './assets/locales/pl/recipe.json';
import RECIPE_RO from './assets/locales/ro/recipe.json';
import RECIPE_CZ from './assets/locales/cz/recipe.json';
import RECIPE_SK from './assets/locales/sk/recipe.json';

import RECIPEUPDATES_EN from './assets/locales/en/recipeUpdates.json';
import RECIPEUPDATES_DE from './assets/locales/de/recipeUpdates.json';
import RECIPEUPDATES_ES from './assets/locales/es/recipeUpdates.json';
import RECIPEUPDATES_FR from './assets/locales/fr/recipeUpdates.json';
import RECIPEUPDATES_IT from './assets/locales/it/recipeUpdates.json';
import RECIPEUPDATES_RO from './assets/locales/ro/recipeUpdates.json';
import RECIPEUPDATES_PL from './assets/locales/pl/recipeUpdates.json';
import RECIPEUPDATES_CZ from './assets/locales/cz/recipeUpdates.json';
import RECIPEUPDATES_SK from './assets/locales/sk/recipeUpdates.json';

import STOCKCOUNT_EN from './assets/locales/en/stockCount.json';
import STOCKCOUNT_DE from './assets/locales/de/stockCount.json';
import STOCKCOUNT_ES from './assets/locales/es/stockCount.json';
import STOCKCOUNT_FR from './assets/locales/fr/stockCount.json';
import STOCKCOUNT_IT from './assets/locales/it/stockCount.json';
import STOCKCOUNT_PL from './assets/locales/pl/stockCount.json';
import STOCKCOUNT_RO from './assets/locales/ro/stockCount.json';
import STOCKCOUNT_CZ from './assets/locales/cz/stockCount.json';
import STOCKCOUNT_SK from './assets/locales/sk/stockCount.json';

import STOCK_COUNT_REVIEW_EN from './assets/locales/en/stockCountReview.json';
import STOCK_COUNT_REVIEW_DE from './assets/locales/de/stockCountReview.json';
import STOCK_COUNT_REVIEW_ES from './assets/locales/es/stockCountReview.json';
import STOCK_COUNT_REVIEW_FR from './assets/locales/fr/stockCountReview.json';
import STOCK_COUNT_REVIEW_IT from './assets/locales/it/stockCountReview.json';
import STOCK_COUNT_REVIEW_PL from './assets/locales/pl/stockCountReview.json';
import STOCK_COUNT_REVIEW_RO from './assets/locales/ro/stockCountReview.json';
import STOCK_COUNT_REVIEW_CZ from './assets/locales/cz/stockCountReview.json';
import STOCK_COUNT_REVIEW_SK from './assets/locales/sk/stockCountReview.json';

import STOCK_COUNT_SCANNING_EN from './assets/locales/en/stockCountScanning.json';
import STOCK_COUNT_SCANNING_DE from './assets/locales/de/stockCountScanning.json';
import STOCK_COUNT_SCANNING_ES from './assets/locales/es/stockCountScanning.json';
import STOCK_COUNT_SCANNING_FR from './assets/locales/fr/stockCountScanning.json';
import STOCK_COUNT_SCANNING_IT from './assets/locales/it/stockCountScanning.json';
import STOCK_COUNT_SCANNING_PL from './assets/locales/pl/stockCountScanning.json';
import STOCK_COUNT_SCANNING_RO from './assets/locales/ro/stockCountScanning.json';
import STOCK_COUNT_SCANNING_CZ from './assets/locales/cz/stockCountScanning.json';
import STOCK_COUNT_SCANNING_SK from './assets/locales/sk/stockCountScanning.json';

import STOCKCOUNTZONES_EN from './assets/locales/en/stockCountZones.json';
import STOCKCOUNTZONES_DE from './assets/locales/de/stockCountZones.json';
import STOCKCOUNTZONES_ES from './assets/locales/es/stockCountZones.json';
import STOCKCOUNTZONES_FR from './assets/locales/fr/stockCountZones.json';
import STOCKCOUNTZONES_IT from './assets/locales/it/stockCountZones.json';
import STOCKCOUNTZONES_PL from './assets/locales/pl/stockCountZones.json';
import STOCKCOUNTZONES_RO from './assets/locales/ro/stockCountZones.json';
import STOCKCOUNTZONES_CZ from './assets/locales/cz/stockCountZones.json';
import STOCKCOUNTZONES_SK from './assets/locales/sk/stockCountZones.json';

import STOCK_TRANSFERS_EN from './assets/locales/en/stockTransfers.json';
import STOCK_TRANSFERS_DE from './assets/locales/de/stockTransfers.json';
import STOCK_TRANSFERS_ES from './assets/locales/es/stockTransfers.json';
import STOCK_TRANSFERS_FR from './assets/locales/fr/stockTransfers.json';
import STOCK_TRANSFERS_IT from './assets/locales/it/stockTransfers.json';
import STOCK_TRANSFERS_PL from './assets/locales/pl/stockTransfers.json';
import STOCK_TRANSFERS_RO from './assets/locales/ro/stockTransfers.json';
import STOCK_TRANSFERS_CZ from './assets/locales/cz/stockTransfers.json';
import STOCK_TRANSFERS_SK from './assets/locales/sk/stockTransfers.json';

import STOCK_TRANSFERS_DETAILS_EN from './assets/locales/en/stockTransfersDetails.json';
import STOCK_TRANSFERS_DETAILS_DE from './assets/locales/de/stockTransfersDetails.json';
import STOCK_TRANSFERS_DETAILS_ES from './assets/locales/es/stockTransfersDetails.json';
import STOCK_TRANSFERS_DETAILS_FR from './assets/locales/fr/stockTransfersDetails.json';
import STOCK_TRANSFERS_DETAILS_IT from './assets/locales/it/stockTransfersDetails.json';
import STOCK_TRANSFERS_DETAILS_PL from './assets/locales/pl/stockTransfersDetails.json';
import STOCK_TRANSFERS_DETAILS_RO from './assets/locales/ro/stockTransfersDetails.json';
import STOCK_TRANSFERS_DETAILS_CZ from './assets/locales/cz/stockTransfersDetails.json';
import STOCK_TRANSFERS_DETAILS_SK from './assets/locales/sk/stockTransfersDetails.json';

import UOMCONVERSIONS_EN from './assets/locales/en/uomConversions.json';
import UOMCONVERSIONS_DE from './assets/locales/de/uomConversions.json';
import UOMCONVERSIONS_ES from './assets/locales/es/uomConversions.json';
import UOMCONVERSIONS_FR from './assets/locales/fr/uomConversions.json';
import UOMCONVERSIONS_IT from './assets/locales/it/uomConversions.json';
import UOMCONVERSIONS_RO from './assets/locales/ro/uomConversions.json'; 
import UOMCONVERSIONS_PL from './assets/locales/pl/uomConversions.json'; 
import UOMCONVERSIONS_CZ from './assets/locales/cz/uomConversions.json'; 
import UOMCONVERSIONS_SK from './assets/locales/sk/uomConversions.json'; 

import WASTECHECKSHEET_EN from './assets/locales/en/wasteChecksheet.json';
import WASTECHECKSHEET_DE from './assets/locales/de/wasteChecksheet.json';
import WASTECHECKSHEET_ES from './assets/locales/es/wasteChecksheet.json';
import WASTECHECKSHEET_FR from './assets/locales/fr/wasteChecksheet.json';
import WASTECHECKSHEET_IT from './assets/locales/it/wasteChecksheet.json';
import WASTECHECKSHEET_RO from './assets/locales/ro/wasteChecksheet.json';
import WASTECHECKSHEET_PL from './assets/locales/pl/wasteChecksheet.json';
import WASTECHECKSHEET_CZ from './assets/locales/cz/wasteChecksheet.json';
import WASTECHECKSHEET_SK from './assets/locales/sk/wasteChecksheet.json';

import SCHEDULED_REPORTS_IT from './assets/locales/it/scheduledReports.json';
import SCHEDULED_REPORTS_EN from './assets/locales/en/scheduledReports.json';
import SCHEDULED_REPORTS_DE from './assets/locales/de/scheduledReports.json';
import SCHEDULED_REPORTS_ES from './assets/locales/es/scheduledReports.json';
import SCHEDULED_REPORTS_FR from './assets/locales/fr/scheduledReports.json';
import SCHEDULED_REPORTS_PL from './assets/locales/pl/scheduledReports.json';
import SCHEDULED_REPORTS_RO from './assets/locales/ro/scheduledReports.json';
import SCHEDULED_REPORTS_CZ from './assets/locales/cz/scheduledReports.json';
import SCHEDULED_REPORTS_SK from './assets/locales/sk/scheduledReports.json';


const resources = {
  en: {
    authentication: AUTHENTICATION_EN,
    common: COMMON_EN,
    createCreditRequest : CREDITDREQUEST_EN,
    createStockCount: CREATESTOCKCOUNT_EN,
    createStockCountSchedule: CREATESTOCKCOUNTSCHEDULE_EN,
    createTransaction: CREATETRANSACTION_EN, 
    drawer: DRAWER_EN,
    errorPage: ERROR_PAGE_EN,
    invoiceDetails: INVOICEDETAILS_EN,
    invoices: INVOICES_EN,
    manageNotifications: MANAGE_NOTIFICATIONS_EN,
    manageConfigurations: MANAGE_CONFIGURATIONS_EN,
    managePodConfigurations: MANAGE_POD_CONFIGURATIONS_EN,
    manageCategories: MANAGE_CATEGORIES_EN,
    manageRoles: MANAGE_ROLES_EN,
    manageTransactions: MANAGETRANSACTIONS_EN,
    manageUsers: MANAGE_USERS_EN,
    orderDetails: ORDER_DETAILS_EN,
    orderForm: ORDER_FORM_EN,
    orderHistory: ORDER_HISTORY_EN,
    products: PRODUCTS_EN,
    productDetails: PRODUCT_DETAILS_EN,
    purchaseOrderDetails: PURCHASEORDERDETAILS_EN,
    quickOrder: QUICK_ORDER_EN,
    recipe: RECIPE_EN,
    recipeUpdates: RECIPEUPDATES_EN,
    stockCount: STOCKCOUNT_EN,
    stockCountReview: STOCK_COUNT_REVIEW_EN,
    stockCountScanning: STOCK_COUNT_SCANNING_EN,
    stockCountZones: STOCKCOUNTZONES_EN,
    stockTransfers: STOCK_TRANSFERS_EN,
    stockTransfersDetails: STOCK_TRANSFERS_DETAILS_EN,
    uomConversions: UOMCONVERSIONS_EN,  
    scheduledReports: SCHEDULED_REPORTS_EN,
    wasteChecksheet: WASTECHECKSHEET_EN,  
  },
  de: {
    authentication: AUTHENTICATION_DE,
    common: COMMON_DE,
    createTransaction: CREATETRANSACTION_DE,
    drawer: DRAWER_DE,
    orderDetails: ORDER_DETAILS_DE,
    orderForm: ORDER_FORM_DE,
    orderHistory: ORDER_HISTORY_DE,
    productDetails: PRODUCT_DETAILS_DE,
    products: PRODUCTS_DE,
    quickOrder: QUICK_ORDER_DE,
    recipeUpdates: RECIPEUPDATES_DE,
    uomConversions: UOMCONVERSIONS_DE,
    wasteChecksheet: WASTECHECKSHEET_DE,
    purchaseOrderDetails: PURCHASEORDERDETAILS_DE,
    createCreditRequest : CREDITREQUEST_DE,
    createStockCount: CREATESTOCKCOUNT_DE,
    createStockCountSchedule: CREATESTOCKCOUNTSCHEDULE_DE,
    errorPage: ERROR_PAGE_DE,
    invoiceDetails: INVOICEDETAILS_DE,
    invoices: INVOICES_DE,
    manageNotifications: MANAGE_NOTIFICATIONS_DE,
    manageConfigurations: MANAGE_CONFIGURATIONS_DE,
    managePodConfigurations: MANAGE_POD_CONFIGURATIONS_DE,
    manageCategories: MANAGE_CATEGORIES_DE,
    manageRoles: MANAGE_ROLES_DE,
    manageTransactions: MANAGETRANSACTIONS_DE,
    manageUsers: MANAGE_USERS_DE,
    recipe: RECIPE_DE,
    stockCount: STOCKCOUNT_DE,
    stockCountReview: STOCK_COUNT_REVIEW_DE,
    stockCountScanning: STOCK_COUNT_SCANNING_DE,
    stockCountZones: STOCKCOUNTZONES_DE,
    stockTransfers: STOCK_TRANSFERS_DE,
    stockTransfersDetails: STOCK_TRANSFERS_DETAILS_DE,
    scheduledReports: SCHEDULED_REPORTS_DE,
  },
  es: {
    authentication: AUTHENTICATION_ES,
    common: COMMON_ES,
    createCreditRequest : CREDITREQUEST_ES,
    createStockCount: CREATESTOCKCOUNT_ES,
    createStockCountSchedule: CREATESTOCKCOUNTSCHEDULE_ES,
    createTransaction: CREATETRANSACTION_ES, 
    drawer: DRAWER_ES,
    errorPage: ERROR_PAGE_ES,
    invoiceDetails: INVOICEDETAILS_ES,
    invoices: INVOICES_ES,
    manageNotifications: MANAGE_NOTIFICATIONS_ES,
    manageConfigurations: MANAGE_CONFIGURATIONS_ES,
    managePodConfigurations: MANAGE_POD_CONFIGURATIONS_ES,
    manageCategories: MANAGE_CATEGORIES_ES,
    manageRoles: MANAGE_ROLES_ES,
    manageTransactions: MANAGETRANSACTIONS_ES,
    manageUsers: MANAGE_USERS_ES,
    orderDetails: ORDER_DETAILS_ES,
    orderForm: ORDER_FORM_ES,
    orderHistory: ORDER_HISTORY_ES,
    products: PRODUCTS_ES,
    productDetails: PRODUCT_DETAILS_ES,
    purchaseOrderDetails: PURCHASEORDERDETAILS_ES,
    quickOrder: QUICK_ORDER_ES,
    recipe: RECIPE_ES,
    recipeUpdates: RECIPEUPDATES_ES,
    stockCount: STOCKCOUNT_ES,
    stockCountReview: STOCK_COUNT_REVIEW_ES,
    stockCountScanning: STOCK_COUNT_SCANNING_ES,
    stockCountZones: STOCKCOUNTZONES_ES,
    stockTransfers: STOCK_TRANSFERS_ES,
    stockTransfersDetails: STOCK_TRANSFERS_DETAILS_ES,
    uomConversions: UOMCONVERSIONS_ES,  
    scheduledReports: SCHEDULED_REPORTS_ES,
    wasteChecksheet: WASTECHECKSHEET_ES,  
  },
  fr: {
    authentication: AUTHENTICATION_FR,
    common: COMMON_FR,
    createCreditRequest : CREDITREQUEST_FR,
    createStockCount: CREATESTOCKCOUNT_FR,
    createStockCountSchedule: CREATESTOCKCOUNTSCHEDULE_FR,
    createTransaction: CREATETRANSACTION_FR, 
    drawer: DRAWER_FR,
    errorPage: ERROR_PAGE_FR,
    invoiceDetails: INVOICEDETAILS_FR,
    invoices: INVOICES_FR,
    manageNotifications: MANAGE_NOTIFICATIONS_FR,
    manageConfigurations: MANAGE_CONFIGURATIONS_FR,
    managePodConfigurations: MANAGE_POD_CONFIGURATIONS_FR,
    manageCategories: MANAGE_CATEGORIES_FR,
    manageRoles: MANAGE_ROLES_FR,
    manageTransactions: MANAGETRANSACTIONS_FR,
    manageUsers: MANAGE_USERS_FR,
    orderDetails: ORDER_DETAILS_FR,
    orderForm: ORDER_FORM_FR,
    orderHistory: ORDER_HISTORY_FR,
    products: PRODUCTS_FR,
    productDetails: PRODUCT_DETAILS_FR,
    purchaseOrderDetails: PURCHASEORDERDETAILS_FR,
    quickOrder: QUICK_ORDER_FR,
    recipe: RECIPE_FR,
    recipeUpdates: RECIPEUPDATES_FR,
    stockCount: STOCKCOUNT_FR,
    stockCountReview: STOCK_COUNT_REVIEW_FR,
    stockCountScanning: STOCK_COUNT_SCANNING_FR,
    stockCountZones: STOCKCOUNTZONES_FR,
    stockTransfers: STOCK_TRANSFERS_FR,
    stockTransfersDetails: STOCK_TRANSFERS_DETAILS_FR,
    uomConversions: UOMCONVERSIONS_FR,  
    scheduledReports: SCHEDULED_REPORTS_FR,
    wasteChecksheet: WASTECHECKSHEET_FR,
  },
  it: {
    authentication: AUTHENTICATION_IT,
    common: COMMON_IT,
    createCreditRequest : CREDITREQUEST_IT,
    createStockCount: CREATESTOCKCOUNT_IT,
    createStockCountSchedule: CREATESTOCKCOUNTSCHEDULE_IT,
    createTransaction: CREATETRANSACTION_IT, 
    drawer: DRAWER_IT,
    errorPage: ERROR_PAGE_IT,
    invoiceDetails: INVOICEDETAILS_IT,
    invoices: INVOICES_IT,
    manageNotifications: MANAGE_NOTIFICATIONS_IT,
    manageConfigurations: MANAGE_CONFIGURATIONS_IT,
    manageCategories: MANAGE_CATEGORIES_IT,
    managePodConfigurations: MANAGE_POD_CONFIGURATIONS_IT,
    manageRoles: MANAGE_ROLES_IT,
    manageTransactions: MANAGETRANSACTIONS_IT,
    manageUsers: MANAGE_USERS_IT,
    orderDetails: ORDER_DETAILS_IT,
    orderForm: ORDER_FORM_IT,
    orderHistory: ORDER_HISTORY_IT,
    products: PRODUCTS_IT,
    productDetails: PRODUCT_DETAILS_IT,
    purchaseOrderDetails: PURCHASEORDERDETAILS_IT,
    quickOrder: QUICK_ORDER_IT,
    recipe: RECIPE_IT,
    recipeUpdates: RECIPEUPDATES_IT,
    stockCount: STOCKCOUNT_IT,
    stockCountReview: STOCK_COUNT_REVIEW_IT,
    stockCountScanning: STOCK_COUNT_SCANNING_IT,
    stockCountZones: STOCKCOUNTZONES_IT,
    stockTransfers: STOCK_TRANSFERS_IT,
    stockTransfersDetails: STOCK_TRANSFERS_DETAILS_IT,
    uomConversions: UOMCONVERSIONS_IT,  
    scheduledReports: SCHEDULED_REPORTS_IT,
    wasteChecksheet: WASTECHECKSHEET_IT,
  },
  ro: {
    authentication: AUTHENTICATION_RO,
    common: COMMON_RO,
    createTransaction: CREATETRANSACTION_RO,
    drawer: DRAWER_RO,
    purchaseOrderDetails: PURCHASEORDERDETAILS_RO,
    orderDetails: ORDER_DETAILS_RO,
    orderForm: ORDER_FORM_RO,
    orderHistory: ORDER_HISTORY_RO,
    productDetails: PRODUCT_DETAILS_RO,
    products: PRODUCTS_RO,
    quickOrder: QUICK_ORDER_RO,
    recipeUpdates: RECIPEUPDATES_RO,
    uomConversions: UOMCONVERSIONS_RO,
    wasteChecksheet: WASTECHECKSHEET_RO,
    createCreditRequest : CREDITREQUEST_RO,
    createStockCount: CREATESTOCKCOUNT_RO,
    createStockCountSchedule: CREATESTOCKCOUNTSCHEDULE_RO,
    errorPage: ERROR_PAGE_RO,
    invoiceDetails: INVOICEDETAILS_RO,
    invoices: INVOICES_RO,
    manageNotifications: MANAGE_NOTIFICATIONS_RO,
    manageConfigurations: MANAGE_CONFIGURATIONS_RO,
    managePodConfigurations: MANAGE_POD_CONFIGURATIONS_RO,
    manageCategories: MANAGE_CATEGORIES_RO,
    manageRoles: MANAGE_ROLES_RO,
    manageTransactions: MANAGETRANSACTIONS_RO,
    manageUsers: MANAGE_USERS_RO,
    recipe: RECIPE_RO,
    stockCount: STOCKCOUNT_RO,
    stockCountReview: STOCK_COUNT_REVIEW_RO,
    stockCountScanning: STOCK_COUNT_SCANNING_RO,
    stockCountZones: STOCKCOUNTZONES_RO,
    stockTransfers: STOCK_TRANSFERS_RO,
    stockTransfersDetails: STOCK_TRANSFERS_DETAILS_RO,
    scheduledReports: SCHEDULED_REPORTS_RO,
  },
  pl:{
    authentication: AUTHENTICATION_PL,
    common: COMMON_PL,
    createCreditRequest : CREDITREQUEST_PL,
    createStockCount: CREATESTOCKCOUNT_PL,
    createStockCountSchedule: CREATESTOCKCOUNTSCHEDULE_PL,
    createTransaction: CREATETRANSACTION_PL, 
    drawer: DRAWER_PL,
    errorPage: ERROR_PAGE_PL,
    invoiceDetails: INVOICEDETAILS_PL,
    invoices: INVOICES_PL,
    manageNotifications: MANAGE_NOTIFICATIONS_PL,
    manageConfigurations: MANAGE_CONFIGURATIONS_PL,
    managePodConfigurations: MANAGE_POD_CONFIGURATIONS_PL,
    manageCategories: MANAGE_CATEGORIES_PL,
    manageRoles: MANAGE_ROLES_PL,
    manageTransactions: MANAGETRANSACTIONS_PL,
    manageUsers: MANAGE_USERS_PL,
    orderDetails: ORDER_DETAILS_PL,
    orderForm: ORDER_FORM_PL,
    orderHistory: ORDER_HISTORY_PL,
    products: PRODUCTS_PL,
    productDetails: PRODUCT_DETAILS_PL,
    purchaseOrderDetails: PURCHASEORDERDETAILS_PL,
    quickOrder: QUICK_ORDER_PL,
    recipe: RECIPE_PL,
    recipeUpdates: RECIPEUPDATES_PL,
    stockCount: STOCKCOUNT_PL,
    stockCountReview: STOCK_COUNT_REVIEW_PL,
    stockCountScanning: STOCK_COUNT_SCANNING_PL,
    stockCountZones: STOCKCOUNTZONES_PL,
    stockTransfers: STOCK_TRANSFERS_PL,
    stockTransfersDetails: STOCK_TRANSFERS_DETAILS_PL,
    uomConversions: UOMCONVERSIONS_PL,  
    scheduledReports: SCHEDULED_REPORTS_PL,
    wasteChecksheet: WASTECHECKSHEET_PL,
  },
  cz: {
    authentication: AUTHENTICATION_CZ,
    common: COMMON_CZ,
    createCreditRequest : CREDITREQUEST_CZ,
    createStockCount: CREATESTOCKCOUNT_CZ,
    createStockCountSchedule: CREATESTOCKCOUNTSCHEDULE_CZ,
    createTransaction: CREATETRANSACTION_CZ, 
    manageConfigurations: MANAGE_CONFIGURATIONS_CZ,
    managePodConfigurations: MANAGE_POD_CONFIGURATIONS_CZ,
    drawer: DRAWER_CZ,
    errorPage: ERROR_PAGE_CZ,
    invoiceDetails: INVOICEDETAILS_CZ,
    invoices: INVOICES_CZ,
    manageNotifications: MANAGE_NOTIFICATIONS_CZ,
    manageCategories: MANAGE_CATEGORIES_CZ,
    manageRoles: MANAGE_ROLES_CZ,
    manageTransactions: MANAGETRANSACTIONS_CZ,
    manageUsers: MANAGE_USERS_CZ,
    orderDetails: ORDER_DETAILS_CZ,
    orderForm: ORDER_FORM_CZ,
    orderHistory: ORDER_HISTORY_CZ,
    products: PRODUCTS_CZ,
    productDetails: PRODUCT_DETAILS_CZ,
    purchaseOrderDetails: PURCHASEORDERDETAILS_CZ,
    quickOrder: QUICK_ORDER_CZ,
    recipe: RECIPE_CZ,
    recipeUpdates: RECIPEUPDATES_CZ,
    stockCount: STOCKCOUNT_CZ,
    stockCountReview: STOCK_COUNT_REVIEW_CZ,
    stockCountScanning: STOCK_COUNT_SCANNING_CZ,
    stockCountZones: STOCKCOUNTZONES_CZ,
    stockTransfers: STOCK_TRANSFERS_CZ,
    stockTransfersDetails: STOCK_TRANSFERS_DETAILS_CZ,
    uomConversions: UOMCONVERSIONS_CZ,  
    scheduledReports: SCHEDULED_REPORTS_CZ,
    wasteChecksheet: WASTECHECKSHEET_CZ,
  },
  sk: {
    authentication: AUTHENTICATION_SK,
    common: COMMON_SK,
    createCreditRequest : CREDITREQUEST_SK,
    createStockCount: CREATESTOCKCOUNT_SK,
    createStockCountSchedule: CREATESTOCKCOUNTSCHEDULE_SK,
    createTransaction: CREATETRANSACTION_SK, 
    drawer: DRAWER_SK,
    errorPage: ERROR_PAGE_SK,
    invoiceDetails: INVOICEDETAILS_SK,
    invoices: INVOICES_SK,
    manageNotifications: MANAGE_NOTIFICATIONS_SK,
    manageConfigurations: MANAGE_CONFIGURATIONS_SK,
    managePodConfigurations: MANAGE_POD_CONFIGURATIONS_SK,
    manageCategories: MANAGE_CATEGORIES_SK,
    manageRoles: MANAGE_ROLES_SK,
    manageTransactions: MANAGETRANSACTIONS_SK,
    manageUsers: MANAGE_USERS_SK,
    orderDetails: ORDER_DETAILS_SK,
    orderForm: ORDER_FORM_SK,
    orderHistory: ORDER_HISTORY_SK,
    products: PRODUCTS_SK,
    productDetails: PRODUCT_DETAILS_SK,
    purchaseOrderDetails: PURCHASEORDERDETAILS_SK,
    quickOrder: QUICK_ORDER_SK,
    recipe: RECIPE_SK,
    recipeUpdates: RECIPEUPDATES_SK,
    stockCount: STOCKCOUNT_SK,
    stockCountReview: STOCK_COUNT_REVIEW_SK,
    stockCountScanning: STOCK_COUNT_SCANNING_SK,
    stockCountZones: STOCKCOUNTZONES_SK,
    stockTransfers: STOCK_TRANSFERS_SK,
    stockTransfersDetails: STOCK_TRANSFERS_DETAILS_SK,
    uomConversions: UOMCONVERSIONS_SK,  
    scheduledReports: SCHEDULED_REPORTS_SK,
    wasteChecksheet: WASTECHECKSHEET_SK,  
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    resources: resources,
  });

export default i18n;