import { Accordion, AccordionDetails, AccordionSummary, Alert, Autocomplete, Box, Button, Checkbox, FormControl, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from './../../components/shared/useUser';
import useNswagClient from './../../hooks/api/useNswagClient';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';
import { Franchise, KeyValuePair_2OfStringAndString, Store } from './../../app/services/api/generated';
import { compare } from './../../utils';
import useLogError from './../../hooks/useLogError';
import { theme } from './../../theme';
import LoadingWheel from './../../components/ui/LoadingWheel';
import { ConfigurationItemsForLevel } from './interfaces/ConfigurationItem';
import { WasteType, wasteTypes } from './enums/WasteType';
import { PosIntegrationType, availableIntegrations } from './enums/PosIntegrationType';
import { ExpandMore } from '@mui/icons-material';
import { availableActivityPeriods } from './enums/RecentActivityPeriod';
import MessagesSnackbar from './../../components/shared/MessagesSnackbar';

import { receiptTransactionsDate } from './enums/ReceiptTransactionDate';
import { CostPriceModel, costPriceModelTranslationMap } from './enums/CostPriceModel';


const ManageConfigurations = () => {
  const { t } = useTranslation('manageConfigurations');
  const { user, isAdmin } = useContext(UserContext);
  const { logError } = useLogError();

  const { getFranchises, getFranchiseStores, getConfigurations, updateConfiguration } = useNswagClient();
  const [allFranchises, setAllFranchises] = useState<Franchise[]>([]);
  const [selectedFranchise, setSelectedFranchise] = useState<Franchise | null>(null);
  const [allStores, setAllStores] = useState<Store[]>([]);
  const [pickedStore, setPickedStore] = useState<Store | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState<boolean>(false);
  const [configurationLevels, setConfigurationLevels] = useState<ConfigurationItemsForLevel[]>([]);

  const readonlyConfigurations = ['CreditRequestsEnabled', 'CreditRequestEvidenceRequired'];

  useEffect(() => {
    loadFranchises();
  }, []);

  useEffect(() => {
    loadStores();
  }, [selectedFranchise]);

  useEffect(() => {
    loadConfigurations();
  }, [selectedFranchise, pickedStore]);

  const loadFranchises = () => {
    getFranchises().then(result => {
      if (result.data) {
        setAllFranchises(result.data.sort((a, b) => compare(a, b, 'franchiseName', 'asc')));
        if (result.data.length === 1) {
          setSelectedFranchise(result.data[0]);
        }
      }
    }).catch((error) => {
      logError(error); 
    });
  };

  const loadStores = () => {
    setAllStores([]);
    setPickedStore(null);

    if (!selectedFranchise?.franchiseName) return;

    if (isAdmin()) {
      getFranchiseStores(selectedFranchise?.franchiseName)
        .then((result) => {
          if (result.data?.stores) {
            setAllStores(result.data?.stores.sort((a, b) => compare(a, b, 'storeName', 'asc')));
          }
        }).catch((error) => {
          logError(error); 
        });
    }
    else {
      setAllStores(user?.stores ?? []);
    }
  };

  const handleFranchiseChange = (event: SyntheticEvent, value: Franchise | null) => {
    setSelectedFranchise(value);
  };

  const handleStoreChange = (event: SyntheticEvent, value: Store | null) => {
    setPickedStore(value);
  };

  const loadConfigurations = () => {
    setIsLoading(true);
    setShowError(false);
    setConfigurationLevels([]);
    getConfigurations(selectedFranchise?.franchiseName ?? '', pickedStore?.storeNumber ?? '')
      .then((result) => {

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const groupedData = Object.values((result.data?.configurations ?? []).reduce((acc: any, config) => {
          const { key, value } = config.level as KeyValuePair_2OfStringAndString;
          const groupKey = `${key}_${value}`;

          if (!acc[groupKey]) {
            acc[groupKey] = {
              level: { key, value },
              values: [],
              expanded: false,
            };
          }
          
          const configItem = {
            id: config.id ?? 0,
            name: config.name ?? '',
            franchiseName: config.franchiseName ?? '',
            storeNumber: config.storeNumber ?? '',
            level: config.level ?? { key: '', value: '' },
            valueType: config.valueType ?? '',
            value: config.valueType === 'Boolean' ? convertStringToBool(config.value ?? '') : config.value ?? '',
            updatedValue: config.valueType === 'Boolean' ? convertStringToBool(config.value ?? '') : config.value ?? '',
            integrationValue: config.valueType === 'Dropdown' && config.name === 'PosIntegration' ? (config.value ? config.value.split('-')[0] : '') : '',
            wasteValue: config.valueType === 'Dropdown' && config.name === 'PosIntegration' && config.value?.split('-')[0] === PosIntegrationType.RTS ? config.value?.split('-')[1] : '',
            recentActivityPeriodValue: config.valueType === 'Dropdown' && config.name === 'RecentActivityPeriod' ? config.value : '',
            receiptTransactionsDateValue:config.valueType === 'Dropdown' && config.name === 'ReceiptTransactionDate' ? config.value : '',
            costPriceModelValue:config.valueType === 'Dropdown' && config.name === 'CostPriceModel' ? config.value : '',
            txtValue: '',
            readonly: readonlyConfigurations.includes(config.name ?? ''),
          };

          if (configItem.valueType === 'Dropdown' && configItem.value != null) {
            const values = (configItem.value as string).split('-');
            const x = values[0];
            const y = values[1];
            const z = values[2];
            if (x === PosIntegrationType.RTS && y === WasteType.GL) {
              configItem.txtValue = z;
            }
            else {
              configItem.txtValue = y;
            }
          }

          acc[groupKey].values.push(configItem);
          return acc;
        }, {}));

        setConfigurationLevels(groupedData as ConfigurationItemsForLevel[]);
      }).catch((error) => {
        logError(error);
        setErrorMessage(error);
        setShowError(true);
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const handleValueChange = (configIndex: number, valueIndex: number, value: string | boolean) => {
    setConfigurationLevels(prevData => {
      const newConfigurations = [...prevData];
      const configItem = newConfigurations[configIndex].values[valueIndex];
      configItem.updatedValue = value;
      return newConfigurations;
    });
  };

  const handleConfigChange = (configIndex: number, valueIndex: number, value: string | undefined, itemProp: 'integrationValue' | 'txtValue' | 'wasteValue' ) => {
    setConfigurationLevels(prevData => {
      const newConfigurations = [...prevData];
      const configItem = newConfigurations[configIndex].values[valueIndex];

      const integrationValue = itemProp === 'integrationValue' ? value : configItem.integrationValue;
      const txtValue = itemProp === 'txtValue' ? value : configItem.txtValue;
      const wasteValue = itemProp === 'wasteValue' ? value : configItem.wasteValue;

      configItem[itemProp] = value ?? '';

      switch (integrationValue) {
      case undefined:
        configItem.updatedValue = '';
        configItem.integrationValue = '';
        configItem.wasteValue = '';
        configItem.txtValue = '';
        break;
      case PosIntegrationType.FRS:
      case PosIntegrationType.Simphony:
        if (!txtValue) {
          configItem.updatedValue = configItem.value;
        } else {
          configItem.updatedValue = `${integrationValue}-${txtValue}`;
        }
        configItem.wasteValue = '';
        break;

      case PosIntegrationType.RTS:
        if (wasteValue === null) {
          configItem.updatedValue = configItem.value;
        } else if (wasteValue === WasteType.GL && (txtValue === null || txtValue === '')) {
          configItem.updatedValue = configItem.value;
        } else {
          configItem.updatedValue = `${integrationValue}-${wasteValue}`;
          if (txtValue !== null && wasteValue !== WasteType.RTS) {
            configItem.updatedValue += `-${txtValue}`;
          }
        }
        break;

      default:
        configItem.updatedValue = configItem.value;
        break;
      }

      return newConfigurations;
    });
  };

  const cancelConfiguration = (configIndex: number, valueIndex: number) => {
    setConfigurationLevels(prevData => {
      const newConfigurations = [...prevData];
      const configItem = newConfigurations[configIndex].values[valueIndex];

      configItem.updatedValue = configItem.value;
      configItem.integrationValue = '';
      configItem.wasteValue = '';
      configItem.txtValue = '';

      if (configItem.valueType === 'Dropdown' && configItem.value != null) {
        const values = configItem.value.split('-');
        const x = values[0];
        const y = values[1];
        const z = values[2];

        switch (x) {
        case PosIntegrationType.Simphony:
        case PosIntegrationType.FRS:
          configItem.integrationValue = x;
          configItem.txtValue = y;
          break;
        case PosIntegrationType.RTS:
          configItem.integrationValue = x;
          configItem.wasteValue = y;
          configItem.txtValue = z;
          break;
        }
      }

      return newConfigurations;
    });
  };

  const saveConfiguration = (configIndex: number, valueIndex: number) => {
    setIsSaving(true);
    const config = configurationLevels[configIndex].values[valueIndex];
    const patchRequest: object = {
      value: config.updatedValue,
    };
    updateConfiguration(config.id, patchRequest)
      .then(() => {
        setConfigurationLevels(prevData => {
          const newConfigurations = [...prevData];
          const configItem = newConfigurations[configIndex].values[valueIndex];
          configItem.value = configItem.updatedValue as string;
          return newConfigurations;
        });
        setShowSuccessMessage(true);
      },
      ).catch((error) => {
        logError(error);
        setErrorMessage(error);
        setShowErrorMessage(true);
      }).finally(() => {
        setIsSaving(false);
      });
  };

  const convertStringToBool = (value: string): boolean => {
    if (!value) return false;

    return value === 'true';
  };
  
  const availableCostPrice = Object.keys(CostPriceModel)
    .filter(key => isNaN(Number(key)))
    .map(key => ({
      key: CostPriceModel[key as keyof typeof CostPriceModel],
      value: t(costPriceModelTranslationMap[CostPriceModel[key as keyof typeof CostPriceModel]]),
    }));

  const renderContent = () => {
    if (isLoading) {
      return <LoadingWheel />;
    }

    if (configurationLevels.length > 0) {
      return configurationLevels.map((item, index) => {
        return (
          <Accordion key={item.level.key}
            sx={{ mb: '12px', p: '5px' }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <Box sx={{ display: 'flex', p: '8px' }}>
                {t('levels.' + item.level.key)} - {item.values.length}
                <Typography sx={{ pl: '5px' }}>
                  {
                    item.values.length > 1 ? t('configurationPlural') : t('configuration')
                  }
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={'div'}
                sx={{ maxHeight: '50vh' }}>
                <Table
                  sx={{ tableLayout: 'fixed' }}
                  size="small"
                  aria-label="Table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {t('name')}
                      </TableCell>
                      <TableCell>
                        {t('value')}
                      </TableCell>
                      <TableCell>
                        {t('valueDetails')}
                      </TableCell>
                      <TableCell></TableCell>
                      {
                        item.level.key !== 'System' && !selectedFranchise && allFranchises.length > 1 && 
                          <TableCell> 
                            {t('franchiseName')}
                          </TableCell>
                      }
                      {
                        item.level.key === 'Store' && !pickedStore && allStores.length > 1 &&
                          <TableCell> 
                            {t('storeNumber')}
                          </TableCell>
                      }
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      item.values.map((row, rowIndex) => (
                        <TableRow key={row.id}>
                          <TableCell
                            component="th"
                            scope="row">
                            {t('configurations.' + row.name)}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row">
                            {
                              (row.valueType === 'Text' || row.valueType === 'Number') &&
                                <TextField
                                  fullWidth
                                  type={row.valueType.toLowerCase()}
                                  value={row.updatedValue ?? ''}
                                  placeholder={t('enterValue')}
                                  disabled={row.readonly}
                                  onChange={($event) => handleValueChange(index, rowIndex, $event.target.value)}>
                                </TextField>
                            }
                            {
                              row.valueType === 'Boolean' && row.updatedValue != null &&
                                <Checkbox
                                  color='primary'
                                  checked={!!row.updatedValue}
                                  disabled={row.readonly}
                                  onChange={($event) => handleValueChange(index, rowIndex, $event.target.checked)}
                                />
                            }
                            {
                              row.valueType === 'Dropdown' && row.name === 'PosIntegration' &&
                                <Autocomplete
                                  fullWidth
                                  options={availableIntegrations}
                                  value={availableIntegrations.find(x => x.key === row.integrationValue) ?? null}
                                  getOptionKey={(option) => option.key ?? ''}
                                  getOptionLabel={(option) => option.value ?? ''}
                                  disabled={row.readonly}
                                  onChange={($event, value) => handleConfigChange(index, rowIndex, value?.key, 'integrationValue')}
                                  renderInput={(params) => (
                                    <TextField {...params}
                                      label={t('integrationType')}
                                      placeholder={t('integrationType')} />
                                  )}
                                />
                            }
                            {
                              row.valueType === 'Dropdown' && row.name === 'RecentActivityPeriod' &&
                                <Autocomplete
                                  options={availableActivityPeriods}
                                  value={availableActivityPeriods.find(x => x.key === row.updatedValue) ?? null}
                                  getOptionKey={(option) => option.key ?? ''}
                                  getOptionLabel={(option) => option.value ?? ''}
                                  disabled={row.readonly}
                                  onChange={($event, value) => handleValueChange(index, rowIndex, value?.key ?? '')}
                                  renderInput={(params) => (
                                    <TextField {...params}
                                      label={t('recentActivityType')}
                                      placeholder={t('recentActivityType')} />
                                  )}
                                />
                            }
                            {
                              row.valueType === 'Dropdown' && row.name === 'ReceiptTransactionDate' &&
                                  <Autocomplete
                                    options={receiptTransactionsDate}
                                    value={receiptTransactionsDate.find(x => x.key === row.updatedValue) ?? null}
                                    getOptionKey={(option) => option.key ?? ''}
                                    getOptionLabel={(option) => option.value ?? ''}
                                    disabled={row.readonly}
                                    onChange={($event, value) => handleValueChange(index, rowIndex, value?.key ?? '')}
                                    renderInput={(params) => (
                                      <TextField {...params}
                                        label={t('receiptTransactionDate')}
                                        placeholder={t('receiptTransactionDate')} />
                                    )}
                                    sx={{ width: '300px' }}
                                  />
                            }
                            {
                              row.valueType === 'Dropdown' && row.name === 'CostPriceModel' &&
                                  <Autocomplete
                                    options={availableCostPrice}
                                    value={availableCostPrice.find(x => x.key === row.updatedValue) ?? null}
                                    getOptionKey={(option) => option.key ?? ''}
                                    getOptionLabel={(option) => option.value ?? ''}
                                    disabled={row.readonly}
                                    onChange={($event, value) => handleValueChange(index, rowIndex, value?.key ?? '')}
                                    renderInput={(params) => (
                                      <TextField {...params}
                                        label={t('selectCostPriceModel')}
                                        placeholder={t('selectCostPriceModel')} />
                                    )}
                                    sx={{ width: '300px' }}
                                  />
                            }

                          </TableCell>
                          <TableCell>
                            {
                              (row.integrationValue === '0' || row.integrationValue === '1') &&
                                <TextField
                                  value={row.txtValue ?? ''}
                                  onChange={($event) => handleConfigChange(index, rowIndex, $event.target.value, 'txtValue')}
                                  placeholder={t('posConnection')}>
                                </TextField>
                            }
                            {
                              row.integrationValue === '2' &&
                                <Autocomplete
                                  options={wasteTypes}
                                  value={wasteTypes.find(x => x.key === row.wasteValue) ?? null}
                                  getOptionKey={(option) => option.key ?? ''}
                                  getOptionLabel={(option) => option.value ?? ''}
                                  onChange={($event, value) => handleConfigChange(index, rowIndex, value?.key, 'wasteValue')}
                                  renderInput={(params) => (
                                    <TextField {...params}
                                      label={t('wasteType')}
                                      placeholder={t('wasteType')} />
                                  )}
                                />
                            }
                          </TableCell>
                          <TableCell>
                            {
                              (row.integrationValue === '2' && row.wasteValue === '0') &&
                                <TextField
                                  value={row.txtValue ?? ''}
                                  placeholder={t('posConnection')}
                                  onChange={($event) => handleConfigChange(index, rowIndex, $event.target.value, 'txtValue')}>
                                </TextField>
                            }
                          </TableCell>
                          {
                            item.level.key !== 'System' && !selectedFranchise && allFranchises.length > 1 && 
                              <TableCell>
                                {row.franchiseName}
                              </TableCell>
                          }
                          {
                            item.level.key === 'Store' && !pickedStore && allStores.length > 1 && 
                              <TableCell>
                                {row.storeNumber}
                              </TableCell>
                          }
                          <TableCell>
                            {
                              row.updatedValue !== row.value && !row.readonly &&
                              <>
                                <Button size='md'
                                  variant='bad'
                                  onClick={() => cancelConfiguration(index, rowIndex)}
                                  disabled={isSaving}
                                  sx={{ mr: '5px' }}>
                                  {t('cancel')}
                                </Button>
                                <Button size='md'
                                  onClick={() => saveConfiguration(index, rowIndex)}
                                  disabled={isSaving}
                                  variant='primary'>
                                  {t('save')}
                                </Button>
                              </>
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <MessagesSnackbar
                  open={showSuccessMessage}
                  onClose={() => setShowSuccessMessage(false)}
                  message={t('successfullyUpdated')}
                  severity="success"
                  duration={3000}
                />
                <MessagesSnackbar
                  open={showErrorMessage}
                  onClose={() => setShowErrorMessage(false)}
                  message={t('errorUpdating') + ' ' + errorMessage}
                  severity="error"
                  duration={null}
                />
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        );
      });
    }

    if (showError) {
      return (
        <Alert severity='error'
          sx={{ display: 'flex', flex: 1, justifyContent: 'left' }}>{t('errorGettingData')}
        </Alert>
      );
    }

    return (
      <Typography variant="textMD"
        sx={{ display: 'flex', flex: 1, justifyContent: 'left' }}>{t('noData')}
      </Typography>
    );
  };

  return (
    <PageArea>
      <Box
        sx={{
          textAlign: 'left',
          color: 'black',
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          alignSelf: 'stretch',
        }}>
        <PageTitle>{' ' + t('title')}</PageTitle>
      </Box>
      <Grid container
        sx={{ mb: '12px' }}
        alignItems={'center'}
        spacing={5}>
        <Grid item
          xs={12}
          md={6}>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              options={allFranchises}
              value={selectedFranchise}
              onChange={handleFranchiseChange}
              getOptionKey={(option) => option.franchiseId ?? ''}
              getOptionLabel={(option) => option.franchiseName ?? ''}
              disabled={allFranchises.length <= 1}
              renderInput={(params) => (
                <TextField {...params}
                  label={t('franchise')}
                  placeholder={t('selectFranchise')} />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item
          xs={12}
          md={6}>
          <FormControl fullWidth>
            <Autocomplete
              fullWidth
              options={allStores}
              value={pickedStore}
              onChange={handleStoreChange}
              getOptionKey={(option) => option.storeNumber ?? ''}
              getOptionLabel={(option) => option.storeName ?? ''}
              renderInput={(params) => (
                <TextField {...params}
                  label={t('store')}
                  placeholder={t('selectStore')} />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box sx={{
        display: 'flex', flexDirection: 'column', width: '100%', height: '100%', p: '12px', border: `solid 1px ${theme.palette.custom.gray[200]}`,
        borderRadius: '4px',
      }}>
        { renderContent() }
      </Box>
    </PageArea>
  );
};

export default ManageConfigurations;