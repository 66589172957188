import axios, { AxiosInstance, AxiosResponse, CancelToken } from 'axios';
import { getApiConfig } from '../../../app/services/environment';

const apiConfig = getApiConfig();

const axiosInstance: AxiosInstance = axios.create({
  baseURL: apiConfig.apiBaseUrl,
  headers: {
    'ews-companyId': apiConfig.ewsCompanyId,
    'ews-apiKey': apiConfig.ewsApiKey,
  },
});

export interface Result<T> {
  success: boolean;
  message: string;
  data: T;
}

export interface PaginatedList<T> {
  items: T[];
  pageNumber: number;
  totalPages: number;
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  sort: string;
  direction: string;
}

export interface Order {
  id: number;
  statusId: number;
  status: string;
  emergencyOrder: boolean;
  submitted: boolean;
  orderTypeId: number;
  orderType: string;
  deliveryDate: string;
  cutOffDate: string;
  cutOffTime: string;
  totalCost: number;
  totalQuantity: number;
  supplierName:string;
}

export interface OrderDetails {
  id: number;
  statusId: number;
  status: string;
  emergencyOrder: boolean;
  submitted: boolean;
  orderTypeId: number;
  orderType: string;
  deliveryDate: string;
  cutOffDate: string;
  cutOffTime: string;
  supplierNumber: string;
  supplierName: string;
  cutOffTimeLeft: string;
  items: OrderItemDetails[];
}

export interface OrderItemDetails {
  id: number;
  itemId: number;
  sku: string;
  name: string;
  primaryUom: string;
  orderableUom: string;
  price: number;
  categoryId: number;
  packSize: string
  category: string;
  stockOnHand: number;
  stockOnOrder: number;
  quantity: number;
  confirmedQuantity: number;
  shippedQuantity: number;
  imageId: number;
  thumbnailUrl: string;
  maxQuantity: number;
  minQuantity: number;
}

export interface OrderItem {
  id: number;
  sku: string;
  quantity: number;
}

export interface OrderType {
  id: number;
  name: string;
}

export interface Category {
  id: number;
  name: string;
  display: boolean;
  showInFilters: boolean;
  showInNavigation: boolean;
  priority: number;
  fullCategoryString: string;
  parentId: number | null;
  parentName: string;
  parentFullCategoryString: string;
}

export interface Item {
  id: number;
  sku: string;
  name: string;
  categoryId: number;
  category: string;
  stockOnHand: number;
  stockOnOrder: number;
  price: number;
  packSize: string;
  primaryUom: string;
  orderableUom: string;
  barcode: string;
  barcodeUom: string;
  imageId: number;
  thumbnailUrl: string;
  maxQuantity: number;
  minQuantity: number;
}
export interface ItemForOrder {
  id: number;
  sku: string;
  name: string;
  categoryId: number;
  category: string;
  stockOnHand: number;
  stockOnOrder: number;
  price: number;
  primaryUom: string;
  orderableUom: string;
  barcode: string;
  barcodeUom: string;
  imageId: number;
  thumbnailUrl: string;
}

export interface AddedItem {
  item: ItemForOrder;
  quantity: number;
  isNew: boolean;
  maxQuantity: number;
  minQuantity: number;
}

export interface DeliverySchedule {
  id: number;
  customerId: number;
  orderType: number;
  cutOffDate: string;
  cutOffTime: string;
  shipDate: string;
  deliveryDate: string;
  supplier: string;
  distributionCenter: string;
}

export interface OrdersFilterOptions {
  storeNumber: string | undefined;
  orderNumber: string | undefined;
  item: string | undefined;
  orderType: number;
  status: number;
  deliveryDateFrom: string | undefined;
  deliveryDateTo: string | undefined;
  pageNumber: number;
  pageSize: number;
  sort: string | undefined;
  direction: string | undefined;
}

export interface ItemsFilterOptions {
  storeNumber: string | undefined;
  item: string | undefined;
  categoryId: number;
  orderType: number;
  supplier: string | undefined;
  pageNumber: number;
  pageSize: number;
  sort: string | undefined;
  direction: string | undefined;
}

export interface ItemFilterParams {
  marketName: string | undefined;
  storeNumber: string | undefined;
  search: string | undefined;
  pageNumber: number;
  pageSize: number;
}

export interface SimpleCategory {
  id: number;
  name: string;
}

export interface CreateOrderRequest {
  storeNumber: string;
  emergencyOrder: boolean;
  orderTypeId: number;
  deliveryDate: string;
  cutOffDate: string;
  supplierNumber: string;
  items: OrderItem[];
}

export interface OrderItem {
  id: number;
  sku: string;
  quantity: number;
}

export interface DeleteOrderItemsRequest {
  orderId: number;
  items: number[];
}

export interface AddOrderItemRequest {
  orderId: number;
  itemId: number;
  quantity: number;
  predictedQuantity?: number;
}

export interface OrderItemUpdate {
  id: number;
  quantity: number;
}

export interface OrderUpdate {
  id: number;
  items: OrderItemUpdate[];
}

export interface OrderForm {
  categories: OrderFormCategory[];
  availableDates: DeliveryDates[];
  isOrderTypeAutoship: boolean;
}
export interface DeliveryDates {
  deliveryDate: string;
  cutOffTime: string; 
}

export interface OrderFormCategory extends Category {
  items: OrderFormItem[];
}

export interface OrderFormItem extends Item {
  quantity: number[];
  lastTimeSold: string;
}

export interface OrderFormUpdate {
  storeNumber: string;
  orderTypeId: number;
  supplierNumber: string;
  items: OrderFormItemUpdate[];
  dates: string[];
}

export interface OrderFormItemUpdate {
  id: number;
  sku: string;
  dates: OrderFormItemUpdateDate[];
}

export interface OrderFormItemUpdateDate {
  date: string;
  quantity: number;
  predictedQuantity?: number;
}

export interface FileResponse {
  fileData: string;
  fileName: string;
}

export interface FileRequest {
  fileData: string;
  fileName: string;
}

export interface Supplier {
  id: number;
  name: string;
  number: string;
}

export interface DsdSupplier extends Supplier {
  enabled: boolean;
}

export interface News{
  id: number;
  title: string;
  teaser: string;
  body: string;
  imageId: number;
  thumbnailUrl: string;
  dataFromOM: boolean;
  startDate:string;
  imagesUrl: string[] | null | undefined;
  files: Files[] | null;
}

export interface Files {
  fileUrl: Item;
  fileDescription: number; 
}
export interface OutOfStockUrgentAction { 
  context: string; 
}

export interface DefaultOrderSettings { 
  isStarbucksStore: boolean;
  isFreshAndDairyEnabled: boolean;
  isAmbientEnabled: boolean;
  isWarningEmailEnabled: boolean;
  dsdSuppliers: DsdSupplier[];
}

class Client {

  private static instance: Client;
  private axiosInstance: AxiosInstance;

  private constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  public static getInstance(): Client {
    if (!Client.instance) {
      Client.instance = new Client(axiosInstance);
    }
    return Client.instance;
  }

  public async getOrders(options: OrdersFilterOptions, cancelToken?: CancelToken): Promise<Result<PaginatedList<Order>>> {
    try {
      const params = new URLSearchParams({
        StoreNumber: options.storeNumber ?? '',
        OrderNumber: options.orderNumber ?? '',
        Item: options.item ?? '',
        OrderType: options.orderType.toString() ?? '',
        Status: options.status.toString() ?? '',
        DeliveryDateFrom: options.deliveryDateFrom ?? '',
        DeliveryDateTo: options.deliveryDateTo ?? '',
        PageNumber: options.pageNumber.toString(),
        PageSize: options.pageSize.toString(),
        Sort: options.sort ?? '',
        Direction: options.direction ?? '',
      }).toString();
      const headers = { 'ews-storeNumber': options.storeNumber };
      const response: AxiosResponse<Result<PaginatedList<Order>>> = await this.axiosInstance.get(`orders?${params}`, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async getOrderTypes(storeNumber: string | undefined, cancelToken?: CancelToken): Promise<Result<OrderType[]>> {
    try {
      const params = new URLSearchParams({
        StoreNumber: storeNumber ?? '',
      }).toString();
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<OrderType[]>> = await this.axiosInstance.get(`ordertypes?${params}`, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async getDeliverySchedules(storeNumber: string | undefined, orderType: number | undefined, supplierNumber: string | undefined, cancelToken?: CancelToken): Promise<Result<DeliverySchedule[]>> {
    try {
      const params = new URLSearchParams({
        StoreNumber: storeNumber ?? '',
        OrderType: orderType?.toString() ?? '',
        SupplierNumber: supplierNumber ?? '',
      }).toString();
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<DeliverySchedule[]>> = await this.axiosInstance.get(`deliveryschedules?${params}`, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async getItems(options: ItemsFilterOptions, cancelToken?: CancelToken): Promise<Result<PaginatedList<Item>>> {
    try {
      const params = new URLSearchParams({
        StoreNumber: options.storeNumber ?? '',
        Item: options.item ?? '',
        OrderType: options.orderType.toString() ?? '',
        Category: options.categoryId?.toString() ?? '',
        Supplier: options.supplier?.toString() ?? '',
        PageNumber: options.pageNumber.toString(),
        PageSize: options.pageSize.toString(),
        Sort: options.sort ?? '',
        Direction: options.direction ?? '',
      }).toString();
      const headers = { 'ews-storeNumber': options.storeNumber };
      const response: AxiosResponse<Result<PaginatedList<Item>>> = await this.axiosInstance.get(`products?${params}`, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async getItemsByFranchiseName(options: ItemFilterParams, cancelToken?: CancelToken): Promise<Result<PaginatedList<Item>>> {
    try {
      const params = new URLSearchParams({
        MarketName: options.marketName ?? '',
        StoreNumber: options.storeNumber ?? '',
        Search: options.search ?? '',
        PageNumber: options.pageNumber.toString(),
        PageSize: options.pageSize.toString(),
      }).toString();
      const headers = { 'ews-storeNumber': options.storeNumber };
      const response: AxiosResponse<Result<PaginatedList<Item>>> = await this.axiosInstance.get(`products/getbymarketname?${params}`, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async getCategories(storeNumber: string | undefined, orderType: number, supplier: string | undefined, cancelToken?: CancelToken): Promise<Result<Category[]>> {
    try {
      const params = new URLSearchParams({
        StoreNumber: storeNumber ?? '',
        OrderType: orderType.toString(),
        Supplier: supplier?.toString() ?? '',
      }).toString();
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<Category[]>> = await this.axiosInstance.get(`categories?${params}`, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async createOrder(request: CreateOrderRequest, cancelToken?: CancelToken): Promise<Result<number>> {
    try {
      const headers = { 'ews-storeNumber': request.storeNumber };
      const response: AxiosResponse<Result<number>> = await this.axiosInstance.post<Result<number>>('orders', request, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async getOrder(storeNumber: string | undefined, orderId: number, cancelToken?: CancelToken): Promise<Result<OrderDetails>> {
    try {
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<OrderDetails>> = await this.axiosInstance.get(`orders/${orderId}`, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async deleteOrderItem(storeNumber: string | undefined, orderId: string, orderItemId: string, cancelToken?: CancelToken): Promise<Result<boolean>> {
    try {
      const url = `orders/${orderId}/OrderItems/${orderItemId}`;
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<boolean>> = await this.axiosInstance.delete(url, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
        return { success: false, message: error.message, data: false };
      } else {
        console.error('Unexpected error:', error);
        return { success: false, message: 'An unexpected error occurred.', data: false };
      }
    }
  }

  public async addOrderItem(storeNumber: string | undefined, request: AddOrderItemRequest, cancelToken?: CancelToken): Promise<Result<boolean>> {
    try {
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<boolean>> = await this.axiosInstance.post<Result<boolean>>('orders/orderitems/add', request, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
        return { success: false, message: error.message, data: false };
      } else {
        console.error('Unexpected error:', error);
        return { success: false, message: 'An unexpected error occurred.', data: false };
      }
    }
  }

  public async deleteOrderItems(storeNumber: string | undefined, request: DeleteOrderItemsRequest, cancelToken?: CancelToken): Promise<Result<boolean>> {
    try {
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<boolean>> = await this.axiosInstance.post<Result<boolean>>('orders/deleteorderitems', request, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async submitOrder(storeNumber: string | undefined, orderId: string, orderDetails: OrderDetails, cancelToken?: CancelToken): Promise<Result<boolean>> {
    try {
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<boolean>> = await this.axiosInstance.post(`orders/submit/${orderId}`, orderDetails, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
        return { success: false, message: error.message, data: false };
      } else {
        console.error('Unexpected error:', error);
        return { success: false, message: 'An unexpected error occurred.', data: false };
      }
    }
  }

  public async getItemByBarcode(storeNumber: string | undefined, barcode: string, stockCount: string | undefined, user: string | undefined, cancelToken?: CancelToken): Promise<Result<Item>> {
    try {
      const headers = { 'ews-storeNumber': storeNumber, 'stock-count': stockCount, 'user': user };
      const response: AxiosResponse<Result<Item>> = await this.axiosInstance.get(`products/barcode/${barcode}`, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async getItemBySku(storeNumber: string | undefined, sku: string, cancelToken?: CancelToken): Promise<Result<Item>> {
    try {
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<Item>> = await this.axiosInstance.get(`products/sku/${sku}`, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async updateOrder(storeNumber: string | undefined, request: OrderUpdate, cancelToken?: CancelToken): Promise<Result<boolean>> {
    try {
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<boolean>> = await this.axiosInstance.post('orders/update', request, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
        return { success: false, message: error.message, data: false };
      } else {
        console.error('Unexpected error:', error);
        return { success: false, message: 'An unexpected error occurred.', data: false };
      }
    }
  }

  public async getOrderByDeliveryDate(storeNumber: string | undefined, orderTypeId: number, deliveryDate: string, supplierNumber: string, cancelToken?: CancelToken): Promise<Result<OrderDetails>> {
    try {
      const params = new URLSearchParams({
        SupplierNumber: supplierNumber,
      }).toString();
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<OrderDetails>> = await this.axiosInstance.get(`orders/ordertype/${orderTypeId.toString()}/deliverydate/${deliveryDate}?${params}`, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async getItem(storeNumber: string | undefined, itemId: number, cancelToken?: CancelToken): Promise<Result<Item>> {
    try {
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<Item>> = await this.axiosInstance.get(`products/${itemId}`, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async copyOrder(storeNumber: string | undefined, orderId: string | undefined, copyOrderId: string | undefined, cancelToken?: CancelToken): Promise<Result<boolean>> {
    try {
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<boolean>> = await this.axiosInstance.post(`orders/${orderId}/copy/${copyOrderId}`, null, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
        return { success: false, message: error.message, data: false };
      } else {
        console.error('Unexpected error:', error);
        return { success: false, message: 'An unexpected error occurred.', data: false };
      }
    }
  }

  public async deleteOrder(storeNumber: string | undefined, orderId: string, cancelToken?: CancelToken): Promise<Result<boolean>> {
    try {
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<boolean>> = await this.axiosInstance.delete(`orders/${orderId}`, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
        return { success: false, message: error.message, data: false };
      } else {
        console.error('Unexpected error:', error);
        return { success: false, message: 'An unexpected error occurred.', data: false };
      }
    }
  }

  public async getOrderForm(storeNumber: string | undefined, orderTypeId: number, categoryId: number, sort: string, direction: string, item: string, supplierNumber: string, cancelToken?: CancelToken): Promise<Result<OrderForm>> {
    try {
      const params = new URLSearchParams({
        OrderType: orderTypeId.toString() ?? '',
        Category: categoryId?.toString() ?? '',
        Sort: sort ?? '',
        Direction: direction ?? '',
        Item: item ?? '',
        SupplierNumber: supplierNumber ?? '',
      }).toString();
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<OrderForm>> = await this.axiosInstance.get(`orders/orderform?${params}`, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async saveOrderForm(storeNumber: string | undefined, request: OrderFormUpdate, cancelToken?: CancelToken): Promise<Result<boolean>> {
    try {
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<boolean>> = await this.axiosInstance.post('orders/orderform', request, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
        return { success: false, message: error.message, data: false };
      } else {
        console.error('Unexpected error:', error);
        return { success: false, message: 'An unexpected error occurred.', data: false };
      }
    }
  }

  public async downloadOrderForm(storeNumber: string | undefined, orderTypeId: number, supplierNumber: string, cancelToken?: CancelToken): Promise<Result<FileResponse>> {
    try {
      const params = new URLSearchParams({ OrderType: orderTypeId.toString(), Supplier: supplierNumber }).toString();
      const headers = { 'ews-storeNumber': storeNumber ?? '' };
      const response = await this.axiosInstance.get<Result<FileResponse>>(`orders/orderform/download?${params}`, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async uploadOrderForm(storeNumber: string | undefined, orderTypeId: number, supplierNumber: string, request: FileRequest, cancelToken?: CancelToken): Promise<Result<boolean>> {
    try {
      const params = new URLSearchParams({ Supplier: supplierNumber }).toString();
      const headers = { 'ews-storeNumber': storeNumber ?? '' };
      const response: AxiosResponse<Result<boolean>> = await this.axiosInstance.post(`orders/orderform/upload/${orderTypeId}?${params}`, request, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async getSuppliers(storeNumber: string | undefined, cancelToken?: CancelToken): Promise<Result<Supplier[]>> {
    try {
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<Supplier[]>> = await this.axiosInstance.get('suppliers', { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async GetNews(storeNumber: string | undefined,  cancelToken?: CancelToken): Promise<Result<News[]>> {
    try {
      const params = new URLSearchParams({
        StoreNumber: storeNumber ?? '',
      }).toString();
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<News[]>> = await this.axiosInstance.get(`news?${params}`, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }
 
  public async GetOutOfStockAlert(storeNumber: string | undefined, cancelToken?: CancelToken): Promise<Result<OutOfStockUrgentAction>> {
    try {
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<OutOfStockUrgentAction>> = await this.axiosInstance.get('outOfStockAlert', { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  } 
  
  public async GetUrgentAlerts(storeNumber: string | undefined, cancelToken?: CancelToken): Promise<Result<OutOfStockUrgentAction>> {
    try {
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<OutOfStockUrgentAction>> = await this.axiosInstance.get('urgentAlert', { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async getDefaultOrderSettings(storeNumber: string | undefined, cancelToken?: CancelToken): Promise<Result<DefaultOrderSettings>> {
    try {
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<DefaultOrderSettings>> = await this.axiosInstance.get('customers/getdefaultordersettings', { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  }

  public async updateDefaultOrderSettings(storeNumber: string | undefined, request: DefaultOrderSettings, cancelToken?: CancelToken): Promise<Result<boolean>> {
    try {
      const headers = { 'ews-storeNumber': storeNumber };
      const response: AxiosResponse<Result<boolean>> = await this.axiosInstance.post('customers/updatedefaultordersettings', request, { headers, cancelToken });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
        return { success: false, message: error.message, data: false };
      } else {
        console.error('Unexpected error:', error);
        return { success: false, message: 'An unexpected error occurred.', data: false };
      }
    }
  }
}

export const client = Client.getInstance();
